import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { ReactSession } from "react-client-session";
import axios from "axios";
import { APP_CONSTANTS } from "../../utils/constants";
import { Divider } from "@mui/material";

class CreateLandingPageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_id: "",
      redirect: false,
      title: "",
      form_name: "",
      cta_text: "",
      cta_target: "",
      intent: "",
      cta_target_url: "",
      cta_message: "",
      button_id: "",
      amount: "",
      collect_payment: false,
      company: "",
      cid: "",
      product_name: "",
      payment_success_message: "",
      payment_failure_message: "",
      payment_description: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.formMeta) {
      let form = this.props.formMeta;
      this.setState({
        form_id: form.id,
        form_name: form.form_name,
        title: form.display_title ? form.display_title : "",
        cta_text: form.cta_text ? form.cta_text : "",
        cta_target: form.cta_target ? form.cta_target : "",
        intent:
          form.cta_target_url === "show_message" ? "show_message" : "open_url",
        cta_target_url: form.cta_target_url ? form.cta_target_url : "",
        cta_message: form.cta_message ? form.cta_message : "",
        button_id: form.button_id ? form.button_id : "",
        amount: form.amount ? form.amount : "",
        company: form.company ? form.company : "",
        collect_payment: form.collect_payment ? form.collect_payment : false,
        cid: form.cid ? form.cid : "",
        product_name: form.product_name ? form.product_name : "",
        payment_success_message: form.payment_success_message
          ? form.payment_success_message
          : "",
        payment_failure_message: form.payment_failure_message
          ? form.payment_failure_message
          : "",
        payment_description: form.payment_description
          ? form.payment_description
          : "",
      });
    }
  }

  handleChange = (e) => {
    let nam = e.target.name;
    if (nam === "collect_payment") {
      this.setState({
        [nam]: !this.state.collect_payment,
      });
    } else {
      let val = e.target.value;
      this.setState({
        [nam]: val,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    var title = this.state.title.trim();
    var form_name = this.state.form_name.trim();
    var cta_text = this.state.cta_text.trim();
    var cta_target = this.state.cta_target.trim();
    var cta_target_url = this.state.cta_target_url.trim();
    var cta_message = this.state.cta_message.trim();
    var amount = this.state.amount;
    var collect_payment = this.state.collect_payment;
    var button_id = this.state.button_id.trim();
    var company = this.state.company.trim();
    let cid = (this.state.cid != null && this.state.cid.trim()) || "";
    cid = cid.toLowerCase();
    cid = cid.replaceAll(" ", "_");
    var product_name = this.state.product_name.trim();
    var payment_success_message = this.state.payment_success_message.trim();
    var payment_failure_message = this.state.payment_failure_message.trim();
    var payment_description = this.state.payment_description.trim();
    // var instruction_file_url = this.state.instruction_file_url.trim();
    this.setState({
      title: title,
      form_name: form_name,
      cta_text,
      cta_target,
      button_id,
      cta_target_url,
      cta_message,
      amount,
      collect_payment,
      company,
      cid,
      product_name,
      payment_success_message,
      payment_failure_message,
      payment_description,
      // description: description,
      // instruction_file_url: instruction_file_url,
    });
    var access_token = "Bearer " + ReactSession.get("access_token");
    var formdata = new FormData();
    formdata.append("display_title", title);
    formdata.append("form_name", form_name);
    formdata.append("cta_text", cta_text);
    formdata.append("cta_target", cta_target);
    formdata.append("cta_target_url", cta_target_url);
    formdata.append("cta_message", cta_message);
    formdata.append("button_id", button_id);
    formdata.append("amount", amount);
    formdata.append("collect_payment", collect_payment);
    formdata.append("company", company);
    formdata.append("cid", cid);
    formdata.append("product_name", product_name);
    formdata.append("payment_success_message", payment_success_message);
    formdata.append("payment_failure_message", payment_failure_message);
    formdata.append("payment_description", payment_description);
    console.log(formdata);
    if (this.props.formMeta === undefined) {
      this.props.handleFormLoading(true);
      axios({
        method: "post",
        url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/forms",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: access_token,
        },
      })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.setState({
              redirect: "/edit_form/" + response.data.content.form_id,
              form_id: response.data.content.form_id,
            });
          }
          this.props.handleFormLoading(false);
        })
        .catch((err) => {
          console.error(String(err));
          if (err.response.status === 400) {
            alert(err.response.data.content.errorMsg);
          }
          this.props.handleFormLoading(false);
        });
    } else {
      axios({
        method: "post",
        url:
          global.config.apiGateway.URL +
          APP_CONSTANTS.ad_sales +
          "/edit_form/" +
          this.state.form_id,
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: access_token,
        },
      })
        .then((response) => {
          console.log(response);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handlePaymentDescriptionState = (e) => {
    this.setState({
      payment_description: this.state.payment_description.replace(
        /[^\x00-\x7F]/g,
        ""
      ),
    });
  };

  handleIntent = (e) => {
    let value = e.target.value;
    console.log(value);
    if (value == "show_message") {
      this.setState({
        cta_target_url: "show_message",
        intent: value,
      });
    } else {
      this.setState({
        cta_target_url: "",
        intent: value,
      });
    }
  };

  handleTarget = (e) => {
    let name = e.target.name;
    console.log(name);
    if (name == "none") {
      this.setState({
        cta_target: "",
      });
    } else {
      this.setState({
        cta_target: "_blank",
      });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div
        className="col-md-12"
        style={{ "margin-bottom": "30px", marginTop: "30px" }}
      >
        <div className="col-md-4 row">
          <div className="col-md-12">
            <h3>Create a Page Form</h3>
            <small>
              A form which can be used to collect leads on landing page
            </small>
          </div>
        </div>
        <form className="form-horizontal col-md-8" onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label className="control-label col-md-3" htmlFor="title">
              Form Display Title*
            </label>
            <div className="col-md-9">
              <input
                required
                type="text"
                className="form-control"
                id="title"
                placeholder="Enter Form Display Title"
                name="title"
                onChange={this.handleChange}
                value={this.state.title}
              />
              <small class="form-text text-muted">
                Title to display above the form
              </small>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-md-3" htmlFor="form_name">
              Form Name*
            </label>
            <div className="col-md-9">
              <input
                required
                type="text"
                className="form-control"
                id="form_name"
                placeholder="Enter Form Name"
                name="form_name"
                onChange={this.handleChange}
                value={this.state.form_name}
              />
              <small class="form-text text-muted"></small>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-3" htmlFor="form_name">
              Component ID
            </label>
            <div className="col-md-9">
              <input
                type="text"
                className="form-control"
                id="cid"
                placeholder="Enter Component ID"
                name="cid"
                onChange={this.handleChange}
                value={this.state.cid}
              />
              <small class="form-text text-muted">
                Give unique identity to the form
              </small>
            </div>
          </div>

          <div
            style={{
              marginBottom: "2rem",
            }}
          >
            <Divider />
          </div>

          <h2>Button data</h2>
          <div className="form-group">
            <div className="form-group">
              <div className="form-group">
                <label className="control-label col-md-3" htmlFor="form_name">
                  CTA Text*
                </label>
                <div className="col-md-9">
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="cta_text"
                    placeholder="Enter Button Text"
                    name="cta_text"
                    onChange={this.handleChange}
                    value={this.state.cta_text}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-md-3" htmlFor="form_name">
                  CTA Target*
                </label>
                {/* <div className="col-md-9">
									<input required type="text" className="form-control" id="cta_target" placeholder="Enter CTA Target" name="cta_target" onChange={this.handleChange} value={this.state.cta_target} />
								</div> */}
                <div className="col-md-9">
                  <div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="none"
                        id="flexRadioDefault1"
                        checked={this.state.cta_target == "" ? true : false}
                        onChange={this.handleTarget}
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        None
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="new_page"
                        id="flexRadioDefault2"
                        checked={!this.state.cta_target == "" ? true : false}
                        onChange={this.handleTarget}
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        New Page
                      </label>
                    </div>
                  </div>
                  <div>
                    <small class="form-text text-muted">
                      Open result in same tab/new tab on submitting the form
                    </small>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-md-3" htmlFor="form_name">
                  Intent*
                </label>
                <div className="col-md-9">
                  <select
                    required
                    id="intent"
                    className="form-control"
                    onChange={this.handleIntent}
                    value={this.state.intent}
                  >
                    <option>Select</option>
                    <option value="open_url">open_url</option>
                    <option value="show_message">show_message</option>
                  </select>
                </div>
                <div style={{ marginLeft: "27%" }}>
                  <small class="form-text text-muted">
                    Show message/open URL on clicking submit button
                  </small>
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-md-3" htmlFor="form_name">
                  CTA Target URL*
                </label>
                <div className="col-md-9">
                  <input
                    disabled={
                      this.state.cta_target_url == "show_message" ? true : false
                    }
                    required
                    type="text"
                    className="form-control"
                    id="cta_target_url"
                    placeholder="Enter Button Target URL"
                    name="cta_target_url"
                    onChange={this.handleChange}
                    value={this.state.cta_target_url}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-md-3" htmlFor="form_name">
                  CTA Message*
                </label>
                <div className="col-md-9">
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="cta_message"
                    placeholder="Enter Button Message"
                    name="cta_message"
                    onChange={this.handleChange}
                    value={this.state.cta_message}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-md-3" htmlFor="form_name">
                  Button ID*
                </label>
                <div className="col-md-9">
                  <input
                    required
                    type="text"
                    className="form-control"
                    id="button_id"
                    placeholder="Enter Button ID"
                    name="button_id"
                    onChange={this.handleChange}
                    value={this.state.button_id}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginBottom: "2rem",
            }}
          >
            <Divider />
          </div>
          <h2>Payment Details</h2>
          <div className="form-group">
            <div className="form-group">
              <label className="control-label col-md-3" htmlFor="form_name">
                Collect Payment
              </label>
              <div className="col-md-9">
                <input
                  type="checkbox"
                  id="collect_payment"
                  placeholder=""
                  name="collect_payment"
                  onChange={this.handleChange}
                  checked={this.state.collect_payment}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-md-3" htmlFor="form_name">
                Company
              </label>
              <div className="col-md-9">
                <input
                  required={this.state.collect_payment ? true : false}
                  type="text"
                  className="form-control"
                  id="company"
                  placeholder="Enter Company Name"
                  name="company"
                  onChange={this.handleChange}
                  value={this.state.company}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-md-3" htmlFor="form_name">
                Product Name
              </label>
              <div className="col-md-9">
                <input
                  required={this.state.collect_payment ? true : false}
                  type="text"
                  className="form-control"
                  id="product_name"
                  placeholder="Enter Product Name"
                  name="product_name"
                  onChange={this.handleChange}
                  value={this.state.product_name}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-md-3" htmlFor="form_name">
                Amount
              </label>
              <div className="col-md-9">
                <input
                  required={this.state.collect_payment ? true : false}
                  type="number"
                  className="form-control"
                  id="amount"
                  placeholder="Enter Amount"
                  name="amount"
                  onChange={this.handleChange}
                  value={this.state.amount}
                />
              </div>
            </div>
            <div className="form-group align-middle">
              <label className="control-label col-md-3" htmlFor="form_name">
                Payment Description <br />
                <small class="form-text text-muted">
                  (To be displayed on Payment Apps)
                </small>
              </label>
              <div className="col-md-9">
                <input
                  required={this.state.collect_payment ? true : false}
                  type="text"
                  className="form-control"
                  id="payment_description"
                  placeholder="Enter Payment Description"
                  name="payment_description"
                  onChange={this.handleChange}
                  onBlur={this.handlePaymentDescriptionState}
                  value={this.state.payment_description}
                />
                <small class="form-text text-muted">
                  Description must be in <b>English</b>* <br />
                </small>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-md-3" htmlFor="form_name">
                Payment Success Message
              </label>
              <div className="col-md-9">
                <input
                  required={this.state.collect_payment ? true : false}
                  type="text"
                  className="form-control"
                  id="payment_success_message"
                  placeholder="Enter Payment Success Message"
                  name="payment_success_message"
                  onChange={this.handleChange}
                  value={this.state.payment_success_message}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-md-3" htmlFor="form_name">
                Payment Failure Message
              </label>
              <div className="col-md-9">
                <input
                  required={this.state.collect_payment ? true : false}
                  type="text"
                  className="form-control"
                  id="payment_failure_message"
                  placeholder="Enter Payment Failure Message"
                  name="payment_failure_message"
                  onChange={this.handleChange}
                  value={this.state.payment_failure_message}
                />
              </div>
            </div>
          </div>
          {!this.props.formMeta ? (
            <div className="form-group">
              <div className="col-md-offset-2 col-md-10">
                <button
                  type="submit"
                  name="submit"
                  className="btn btn-default"
                  style={{ float: "right" }}
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <div className="form-group">
              <div className="col-md-offset-2 col-md-10">
                <button
                  type="submit"
                  name="update"
                  className="btn btn-default"
                  style={{ float: "right" }}
                >
                  Update
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default CreateLandingPageForm;
