import React, { Component } from "react";
import { ReactSession } from "react-client-session";
import { APP_CONSTANTS } from '../../utils/constants';
import axios from "axios";
import { v4 as uuid } from 'uuid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Loader from "../Loader";

class FormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: [{ id: uuid(), icon_url: "", icon_text: "" }],
      text: "",
      cId: "",
      url: "",
      formTargetUrl: "",
      newPage: "none",
      ctaText: "",
      logical_id: "",
      loading: false
    };
  }
  putData() {
    let ctype = this.props.ctype;
    let data = this.props.data;
    this.setState({
      cId: data.cid,
      logical_id: data.logical_id
    });

    if (ctype == "heading" || ctype == "sub_text" || ctype == "normal_text") {
      this.setState({
        text: data.cdata,
      })
    }

    if (ctype == "static_image") {
      this.setState({
        url: data.cdata,
      })
    }

    if (ctype == "button") {
      this.setState({
        formTargetUrl: data.cdata.target_url,
        ctaText: data.cdata.text,
        newPage: data.cdata.target
      })
    }

    if (ctype == "carousel" || ctype == "icons_with_text") {
      let urls = data.cdata;
      let urlsobj = [];
      if (ctype == "carousel") {
        for (let i = 0; i < urls.length; i++) {
          let obj = {};
          obj["id"] = uuid();
          console.log("urls[i]", urls[i]);
          obj["icon_url"] = urls[i];
          obj["icon_text"] = "";
          urlsobj.push(obj);
        }
      } else {
        for (let i = 0; i < urls.length; i++) {
          let obj = { ...urls[i] };
          obj["id"] = uuid();
          urlsobj.push(obj);
        }
      }
      this.setState({
        formValues: urlsobj
      })
    }

    if (ctype == "icons_with_text") {
      this.setState({
        formValues: data.cdata
      })
    }
  }
  componentDidMount() {
    if (this.props.data) {
      this.putData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.ctype != this.props.ctype) && this.props.data == undefined) {
      this.setState({
        formValues: [{ id: uuid(), icon_url: "", icon_text: "" }],
        text: "",
        cId: "",
        url: "",
        formTargetUrl: "",
        newPage: "",
        ctaText: "",
        logical_id: "",
      })
    } else {
      if (this.props.render) {
        this.putData();
        this.props.onRender();
      }
    }
  }
  handleChangeFeature(i, e) {
    let formValues = this.state.formValues;
    formValues[i][e.target.name] = e.target.value;
    this.setState({ formValues });
  }
  handleChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;

    this.setState({
      [nam]: val,
    });
  };

  addFormFields() {
    this.setState({
      formValues: [...this.state.formValues, { id: uuid(), icon_url: "", icon_text: "" }],
    });
  }

  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }

  handleComponentSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true })
    console.log("submit");
    let ctype = this.props.ctype;
    let cid = (this.state.cId !=null && this.state.cId.trim())|| "";
    cid = cid.toLowerCase();
    cid = cid.replaceAll(" ", "_");
    let obj = {};
    obj["ctype"] = ctype;
    obj["cid"] = cid;
    if (ctype == "heading" || ctype == "sub_text" || ctype == "normal_text") {
      obj["cdata"] = this.state.text;
      obj["cdatatype"] = "key";
    }
    if (ctype == "static_image") {
      obj["cdata"] = this.state.url;
      obj["cdatatype"] = "key";
    }

    if (ctype == "carousel") {
      let arr = [];
      let formValues = this.state.formValues;
      for (let i = 0; i < formValues.length; i++) {
        arr.push(formValues[i].icon_url);
      }
      obj["cdata"] = arr;
      obj["cdatatype"] = "json_array";
    }

    if (ctype == "icons_with_text") {
      obj["cdata"] = this.state.formValues;
      obj["cdatatype"] = "json_array";
    }

    if (ctype == "button") {
      let bobj = {};
      bobj["text"] = this.state.ctaText;
      bobj["canButtonSubmit"] = true;
      bobj["target"] = this.state.newPage;
      bobj["target_url"] = this.state.formTargetUrl;
      obj["cdata"] = bobj;
      obj["cdatatype"] = "json"

    }
    var access_token = "Bearer " + ReactSession.get("access_token");
    if (!this.props.data) {
      let postData = JSON.stringify(obj);
      console.log("pdadd", postData);
      axios({
        method: "post",
        url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/add_page_component/" + this.props.pageId,
        data: postData,
        headers: {
          "Content-Type": "application/json",
          Authorization: access_token
        }
      }).then((response) => {
        console.log(response);
        window.location.reload();
        this.setState({ loading: false })
      }).catch((err) => {
        console.log("add", err);
        this.setState({ loading: false })
      })
    } else {
      obj["logical_id"] = this.state.logical_id;
      console.log(this.state.logical_id)
      let postData = JSON.stringify(obj);
      console.log("pd", postData);
      axios({
        method: "post",
        url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/edit_page_component/" + this.props.pageId,
        data: postData,
        headers: {
          "Content-Type": "application/json",
          Authorization: access_token
        }
      }).then((response) => {
        console.log(response);
        window.location.reload();
        this.setState({ loading: false })
      }).catch((err) => {
        console.log("add", err);
        this.setState({ loading: false })
      })
    }


  }

  handleFieldTextChange = (e) => {
    let id = e.target.getAttribute('fieldid');
    let newArr = [...this.state.formValues];
    let obj = newArr.find((element) => {
      return element.id === id;
    })
    obj["icon_text"] = e.target.value;
    this.setState({
      formValues: newArr
    })
  }

  handleFieldURLChange = (e) => {
    let id = e.target.getAttribute('fieldid');
    console.log("id", id);
    let newArr = [...this.state.formValues];
    newArr.forEach((element) => {
      if (element.id === id) {
        element["icon_url"] = e.target.value;
      }
    })
    console.log("caraoual", newArr)
    this.setState({
      formValues: newArr
    })
  }

  handleTarget = (e) => {
    let name = e.target.name;
    console.log(name);
    if (name == 'none') {
      this.setState({
        newPage: ''
      })
    } else {
      this.setState({
        newPage: '_blank'
      })
    }
  }

  render() {
    return (
      <div className="container">
        {this.state.loading ? <Loader /> :
          <>
            {this.props.ctype == "form" &&
              <div className="panel panel-default" style={!this.props.data ? { marginTop: "15vh" } : { marginTop: "0vh" }}>
                <div className="panel panel-heading">Form</div>
                <div className="panel-body box-border ">
                  <form
                    id={"create-component-" + this.props.ctype}
                    className="form-horizontal col-md-8"
                  >
                    <label className="" htmlFor="text">
                      Form ID:
                    </label>
                    <span>{" " + this.props.data.cdata.form_id}</span><br />

                    <label className="" htmlFor="text">
                      Form Name:
                    </label>
                    <span>{" " + this.props.data.form_name}</span>
                  </form>
                </div>
              </div>}
            {this.props.ctype === "heading" ||
              this.props.ctype === "sub_text" ||
              this.props.ctype === "button" ||
              this.props.ctype === "normal_text" ||
              this.props.ctype === "static_image" ? (
              <div className="panel panel-default" style={!this.props.data ? { marginTop: "15vh" } : { marginTop: "0vh" }}>
                <div className="panel panel-heading">
                  {
                    {
                      heading: "Heading",
                      sub_text: "Sub Text",
                      button: "Button",
                      normal_text: "Normal Text",
                      static_image: "Static Image",
                    }[this.props.ctype]
                  }
                </div>
                <div className="panel-body box-border ">
                  <form
                    id={"create-component-" + this.props.ctype}
                    className="form-horizontal col-md-8"
                  >
                    {this.props.ctype === "heading" ||
                      this.props.ctype === "sub_text" ||
                      this.props.ctype === "normal_text" ? (
                      <div className="form-group">
                        <label className="control-label col-md-3" htmlFor="text">
                          Text
                        </label>
                        <div className="col-md-9">
                          <input
                            required
                            type="text"
                            className="form-control"
                            id="heading_text"
                            placeholder="Enter Heading"
                            name="text"
                            onChange={this.handleChange}
                            value={this.state.text}
                          />
                        </div>
                      </div>
                    ) : null}
                    {this.props.ctype === "static_image" ? (
                      <div className="form-group">
                        <label
                          className="control-label col-md-3"
                          htmlFor="client_id"
                        >
                          Upload Image
                        </label>
                        <div className="col-md-9">
                          <input
                            required
                            type="url"
                            className="form-control"
                            id="client_id"
                            placeholder="Enter Image Url"
                            name="url"
                            onChange={this.handleChange}
                            value={this.state.url}
                          />
                        </div>
                      </div>)
                      : null}
                    <div className="form-group">
                      <label
                        className="control-label col-md-3"
                        htmlFor="client_id"
                      >
                        CId
                      </label>
                      <div className="col-md-9">
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="client_id"
                          placeholder="Enter Component Id"
                          name="cId"
                          onChange={this.handleChange}
                          value={this.state.cId}
                        />
                        <small>Logical HTML Id</small>

                      </div>
                    </div>
                    {this.props.ctype === "button" ? (
                      <div>
                        <div className="form-group">
                          <label className="control-label col-md-3" htmlFor="text">
                            Form Target Url
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              className="form-control"
                              id="heading_text"
                              placeholder="Enter Target Url"
                              name="formTargetUrl"
                              onChange={this.handleChangeFeature}
                              value={this.state.formTargetUrl}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-3" htmlFor="text">
                            New Page*
                          </label>
                          <div className="col-md-9">
                            <div >
                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="none" id="flexRadioDefault1" checked={this.state.newPage == '' ? true : false} onChange={this.handleTarget} />
                                <label class="form-check-label" for="flexRadioDefault1">
                                  None
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="radio" name="new_page" id="flexRadioDefault2" checked={!this.state.newPage == '' ? true : false} onChange={this.handleTarget} />
                                <label class="form-check-label" for="flexRadioDefault2">
                                  New Page
                                </label>
                              </div>
                            </div>
                            <div>
                              <small class="form-text text-muted">
                                Open result in same tab/new tab on submitting the form
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-md-3" htmlFor="text">
                            CTA Text*
                          </label>
                          <div className="col-md-9">
                            <input
                              required
                              type="text"
                              className="form-control"
                              id="heading_text"
                              placeholder="Enter Button Text"
                              name="ctaText"
                              onChange={this.handleChange}
                              value={this.state.ctaText}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="form-group">
                      <div className="col-md-offset-2 col-md-10  ">
                        {!this.props.data ? <button
                          type="button"
                          name="submit"
                          className="btn btn-default"
                          style={{ float: "right" }}
                          onClick={this.handleComponentSubmit}
                        >

                          {this.props.ctype === "static_image"
                            ? "Upload"
                            : "Submit"}
                        </button> : <button
                          type="button"
                          name="update"
                          class="btn btn-sm btn-primary mx-2"
                          onClick={this.handleComponentSubmit}
                        >
                          Update
                        </button>}

                      </div>
                    </div>
                  </form>
                </div>
                <div className="panel-footer"></div>
              </div >
            ) : (
              ""
            )
            }
            {
              this.props.ctype === "icons_with_text" ||
                this.props.ctype === "carousel" ? (
                <div className="panel panel-default" style={!this.props.data ? { marginTop: "15vh" } : { marginTop: "0vh" }}>
                  <div className="panel panel-heading">
                    {this.props.ctype === "icons_with_text"
                      ? "Feature Box"
                      : "Carousel"}
                  </div>
                  <div className="panel-body box-border ">
                    <form
                      id="create-component-featureCarousel"
                      className="form-horizontal col-md-10"
                      onSubmit={this.handleComponentSubmit}
                    >
                      <div className="form-group">
                        <div className="form-group col-md-4 pl-10">
                          <label>cId*</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            id="client_id"
                            placeholder="Enter Component Id"
                            name="cId"
                            onChange={this.handleChange}
                            value={this.state.cId}
                          />
                        </div>
                      </div>
                      {this.state.formValues.map((element, index) => (
                        <div className="form-group" key={index}>
                          <div className="form-group col-md-4 pl-10">
                            <label for="inputEmail4">Image*</label>
                            <input
                              required
                              type="text"
                              className="form-control"
                              id="inputEmail4"
                              placeholder="Enter URL for image"
                              value={element.icon_url}
                              onChange={this.handleFieldURLChange}
                              fieldid={element.id}
                            />
                          </div>
                          {this.props.ctype === "icons_with_text" ? (
                            <div className="form-group col-md-4 pl-10">
                              <label for="inputPassword4">Text*</label>
                              <input
                                required
                                type="text"
                                className="form-control"
                                id="inputPassword4"
                                placeholder="Text"
                                value={element.icon_text}
                                onChange={this.handleFieldTextChange}
                                fieldid={element.id}
                              />
                            </div>
                          ) : null}

                          {index ? (
                            <button
                              type="button"
                              className="btn btn-danger col-md-2 mt-10 ml-10"
                              onClick={() => this.removeFormFields(index)}
                            >
                              Remove
                            </button>
                          ) : null}
                        </div>
                      ))}
                      <div className="panel-footer">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={() => this.addFormFields()}
                        >
                          Add
                        </button>
                        {!this.props.data ? <button name="submit" className="btn btn-success ml-3" type="submit">
                          Submit
                        </button> : <button
                          type="submit"
                          name="update"
                          class="btn btn-sm btn-primary mx-2"
                        >
                          Update
                        </button>}

                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                ""
              )
            }
          </>}
      </div >
    );
  }
}

export default FormComponent;
