import React, { Component } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import { APP_CONSTANTS } from '../../utils/constants';
import CreateLandingPageForm from './create_page_form';
import { Link, useHistory } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import Modal from '../modal/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Toast from '../Toast'
import Loader from '../Loader';
import { formatDateTime, formatDateTimeAM } from 'utils/datetime';

class LandingPageForms extends Component {
	constructor(props) {
		super(props);
		console.log("forms url", window.location.host)
		this.state = {
			forms: [],
			logged_in: false,
			username: '',
			show_form: false,
			pages: 0,
			curPage: 1,
			showModal: false,
			modalMsg: "",
			currFormId: "",
			deleteForm: false,
			activateForm: false,
			loading: true,
			showToast: false,
			toastMsg: "",
			continue: false
		}

		if (typeof ReactSession.get("username") !== 'undefined') {
			this.state.logged_in = true;
			this.state.username = ReactSession.get("username");
		}
		this.hide_or_show_form = this.hide_or_show_form.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
		this.setItems = this.setItems.bind(this);
	}

	closeModalHandler = () => this.setState({ showModal: false });


	componentDidMount() {
		if (this.state.logged_in)
			this.fetch_forms();
	}

	setItems = async (items) => {
		this.setState({
			forms: [],
		})
		items.map((object) => {
			this.setState({
				forms: this.state.forms.concat([object]),
			})
		})
	}

	handlePageClick = async (data) => {
		let currentPage = data.selected + 1;
		this.setState({
			curPage: currentPage
		})
		const dataFormServer = await this.fetchData(currentPage);
		console.log(dataFormServer)
		this.setItems(dataFormServer);
	};

	fetchData = async (currentPage) => {
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var data = [];
		data = axios.get(global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + `/forms?page=${currentPage}`,
			{ headers: { Authorization: access_token } })
			.then((response) => {
				return response.data.content
			})
			.catch((err) => {
				console.error(String(err));
				return [];
			});
		return data
	}

	fetch_forms = () => {
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		var data = [];
		axios.get(global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/forms",
			{ headers: { Authorization: access_token } })
			.then((response) => {
				console.log("res", response);
				this.setState({
					pages: Math.ceil(response.data.total / 10)
				})
				response.data.content.map((object) => {
					this.setState({
						forms: this.state.forms.concat([object]),
					}, function () {
						// console.log("data callback", this.state.forms);
					})
				})
				this.setState({ loading: false })
			})
			.catch((err) => {
				console.error(String(err));
				this.setState({ loading: false })
			});
		console.log(this.state.forms, data);
	}


	hide_or_show_form = () => {
		this.setState({ show_form: !this.state.show_form });
	}

	closeDeleteForm = (e) => {
		this.setState({ deleteForm: false })
	}

	formRefresh = (e) => {
		let access_token = 'Bearer ' + ReactSession.get("access_token");
		this.setState({ loading: true });
		axios({
			method: "get",
			url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/refresh_form_in_page/" + e.target.id,
			headers: { Authorization: access_token }
		}).then((response) => {
			console.log("refresh", response.data);
			this.setState({ loading: false });
			this.setState({
				showToast: true,
				toastMsg: "The Form is Refreshed"
			})

			setTimeout(() => {
				this.setState({
					showToast: false,
					toastMsg: "",
				})
			}, 4000)
		}).catch((err) => {
			console.log(err);
		})
	}

	handleDuplicateForm = (e) => {
		let access_token = 'Bearer ' + ReactSession.get("access_token");
		axios({
			method: "get",
			url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/duplicate_form/" + e.target.id,
			headers: { Authorization: access_token }
		}).then((response) => {
			console.log("dup", response.data);
			let dupId = response.data.content.form_id;
			this.props.history.push(`/edit_form/${dupId}`);
		}).catch((err) => {
			console.log(err);
		})
	}

	handleFormActivate = () => {
		let access_token = 'Bearer ' + ReactSession.get("access_token");
		let formdata = new FormData();
		formdata.append("is_active", true);
		axios({
			method: "post",
			url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/edit_form/" + this.state.currFormId,
			data: formdata,
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: access_token
			}
		}).then((response) => {
			console.log(response);
			window.location.reload();
			//props.setOpenModal(false);
		}).catch((err) => {
			console.log(err);
			this.setState({
				showToast: true,
				toastMsg: err
			})

			setTimeout(() => {
				this.setState({
					showToast: false,
					toastMsg: "",
				})
			}, 4000)
		})
	}

	handleDeleteForm = () => {
		let access_token = 'Bearer ' + ReactSession.get("access_token");
		axios({
			method: "get",
			url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/delete_form/" + this.state.currFormId,
			headers: { Authorization: access_token }
		}).then((response) => {
			console.log(response);
			window.location.reload();
			//props.setOpenModal(false);
		}).catch((err) => {
			console.log(err);
			this.setState({
				showToast: true,
				toastMsg: err
			})

			setTimeout(() => {
				this.setState({
					showToast: false,
					toastMsg: "",
				})
			}, 4000)
		})
	}

	renderFormList = (forms) => (

		forms.map((form, idx) => (
			<tr>
				<td>{form.id}</td>
				<td>{form.form_name}</td>
				<td><Link to={`/edit_page_component/${form.page_id}`}>{form.page_id}</Link></td>
				<td>{form.is_active === true ? "✅" : "❌"}</td>
				<td>{formatDateTimeAM(form.created_at)}</td>
				<td>{formatDateTimeAM(form.updated_at)}</td>
				<td>
					<ul>
						<li>
							<Link to={'/view_form/' + form.id} >View <i class="fa fa-eye" aria-hidden="true"></i></Link>
						</li>
						{!form.is_active && (<li>
							<Link to={'/edit_form/' + form.id} >Edit<i class="fas fa-edit"></i></Link>
						</li>)}
						{/* change this to link */}
						{form.page_id === null && (<li>
							<Link to={'/attach_form/' + form.id} >Attach<i class="fas fa-link"></i></Link>
						</li>)}
						{form.page_id !== null && (<li>
							<button id={form.page_id} onClick={this.formRefresh}>Refresh</button>
						</li>)}
						{form.is_active && (
							<li>
								<Link to={"/export_lead/" + form.id}>Export</Link>
							</li>
						)}

						<li>
							<button id={form.id} onClick={this.handleDuplicateForm}>Duplicate</button>
						</li>

						{form.page_id == null && (<li>
							{/* <Link to={'/delete_form/' + form.id} >Delete <i class="fas fa-trash"></i></Link> */}
							{/* <button id={form.id} onClick={this.handleDeleteForm}>Delete <i class="fas fa-trash"></i></button> */}
							<button
								className="openModalBtn"
								onClick={() => {
									this.setState({
										activateForm: false,
										deleteForm: true
									})
									this.setState({
										currFormId: form.id,
										modalMsg: "Are you sure you want to delete the form?"
									})
									this.setModalOpen(true);
								}}
							>
								Delete
							</button>
						</li>)}

						{!form.is_active && (<li>
							{/* <Link to={'/activate_form/' + form.id} >Activate<i class="fa fa-eye" aria-hidden="true"></i></Link> */}
							<button
								className="openModalBtn"
								onClick={() => {
									this.setState({
										activateForm: true,
										deleteForm: false
									})
									this.setState({
										currFormId: form.id,
										modalMsg: "Are you sure you want to activate the form. After Activating the form editing will not be possible"
									})
									this.setModalOpen(true);
								}}
							>
								Activate
							</button>
						</li>)}
					</ul>
				</td>
			</tr>
		)))

	setModalOpen = (bool) => {
		this.setState({
			showModal: bool
		})

		if (bool == false) {
			this.setState({
				activateForm: false,
				deleteForm: false,
			})
		}
	}

	handleModalContinue = () => {
		if (this.state.activateForm) {
			this.handleFormActivate();
			this.setModalOpen(false);
		} else if (this.state.deleteForm) {
			this.handleDeleteForm();
			this.setModalOpen(false);
		}
	}

	handleFormLoading = (bool) => {
		this.setState({ loading: bool });
	}



	render() {
		if (!this.state.logged_in) {
			return (
				<div>
					<h5>Not logged in !</h5>
				</div>
			);
		}
		return (
			<>
				{this.state.showToast ? <Toast msg={this.state.toastMsg} /> : <></>}
				{this.state.loading ? <Loader /> :
					<div class="container">
						{this.state.showModal && <Modal setOpenModal={this.setModalOpen} handleModalContinue={this.handleModalContinue} msg={this.state.modalMsg} />}
						< div class="row" >
							<h2 style={{ "display": "inline-block" }} className="mb-2">Forms</h2>
							<button className="btn btn-success pull-right mt-5" onClick={this.hide_or_show_form} type="submit">Create Form</button>
						</div>
						{this.state.show_form && <CreateLandingPageForm handleFormLoading={this.handleFormLoading} />}
						<div class="row">
							<table className="table mb-0">
								<thead className="bg-light">
									<tr>
										<th scope="col" className="border-0">
											Form Id
										</th>
										<th scope="col" className="border-0">
											Form Name
										</th>
										<th scope="col" className="border-0">
											Page Id
										</th>
										<th scope="col" className="border-0">
											Is Active
										</th>
										<th scope="col" className="border-0">
											Created At
										</th>
										<th scope="col" className="border-0">
											Updated At
										</th>
										<th scope="col" className="border-0">
											Actions
										</th>
									</tr>
								</thead>
								<tbody>
									{this.renderFormList(this.state.forms)}
								</tbody>
							</table>
							<div>
								<ReactPaginate
									previousLabel={"previous"}
									nextLabel={"next"}
									breakLabel={"..."}
									pageCount={this.state.pages}
									marginPagesDisplayed={2}
									pageRangeDisplayed={3}
									onPageChange={this.handlePageClick}
									containerClassName={"pagination justify-content-center"}
									pageClassName={"page-item"}
									pageLinkClassName={"page-link"}
									previousClassName={"page-item"}
									previousLinkClassName={"page-link"}
									nextClassName={"page-item"}
									nextLinkClassName={"page-link"}
									breakClassName={"page-item"}
									breakLinkClassName={"page-link"}
									activeClassName={"active"}
								/>
							</div>

						</div>

					</div >}
			</>
		);
	}
}

export default LandingPageForms;
