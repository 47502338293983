const dev = {
    apiGateway: {
        URL: process.env.REACT_APP_LOCALHOST==="1"? "http://localhost:5000" : "https://dev.api.krishi.network/",
        page_url:"https://dev.krishi.network/test_landingpage"
    }
};
  
const prod = {
    apiGateway: {
        URL: "https://thekrishi.com/v2",
        page_url:"https://krishi.network/landingpage"
    }
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;
// console.log("env",process.env);
// console.log(process.env.LOCALHOST);
// console.log(config.apiGateway.URL)

export default config;