import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { ReactSession } from "react-client-session";
import axios from "axios";
import { APP_CONSTANTS } from "../../../utils/constants";


class FieldOptionsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      option_text: "",
      type: "",
      validation_required: true,
      is_required: true,
      position: this.props.data.position,
      form_id: this.props.data.form_id,
    };
    console.log("props data", this.props.data);
    console.log("position", this.state.position, this.state.form_id);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;

    this.setState({
      [nam]: val,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData(document.getElementById("create_field"));
    for (var value of formdata.values()) {
      console.log(value);
    }
    console.log("state", this.state);
    var access_token = "Bearer " + ReactSession.get("access_token");

    formdata.append("position", this.state.position);
    for (let value of formdata.values()) {
      console.log(value);
    }
    console.log(formdata);
    axios({
      method: "post",
      url:
        global.config.apiGateway.URL +
        APP_CONSTANTS.ad_sales +
        "/add_form_field_option/" +
        this.state.form_id,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          console.log("response", response.data.content);
          window.location.reload();
        }
      })
      .catch((err) => {
        console.error(String(err));
        if (err.response.status === 400) {
          console.log(err.response);
        }
      });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div className="col-md-12" style={{ "margin-bottom": "30px" }}>
        <div className="col-md-4 row">
          <div className="col-md-12">
            <h3>Add options to field</h3>
            <small>options are the part of a dropdown/radio, A single item represent a single dropdown item</small>
          </div>
        </div>
        <form
          id="create_field"
          className="form-horizontal col-md-8"
          onSubmit={this.handleSubmit}
        >
          <div className="form-group">
            <label className="control-label col-md-3" htmlFor="fieldText">
              Option Text
            </label>
            <div className="col-md-9">
              <input
                required
                type="text"
                className="form-control"
                id="field_text"
                placeholder="Enter Option Text"
                name="option_text"
                onChange={this.handleChange}
                value={this.state.option_text}
              />
              <small>* Add multiple fields via comma seperated</small>
            </div>
          </div>
          <div className="form-group">
            <div className="col-md-offset-2 col-md-10">
              <button
                type="submit"
                className="btn btn-default"
                style={{ float: "right" }}
              >Add
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default FieldOptionsForm;
