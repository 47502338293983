import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import { APP_CONSTANTS } from '../../utils/constants';
import { Link, useHistory } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Loader from '../Loader';
import Modal from '../modal/Modal';
import { formatDateTimeAM } from 'utils/datetime';

function ClientTable(props) {
    const history = useHistory();
    console.log("p", props?.clientad)
    
    return (
        <>
            {props?.clientad?.map((item, idx) => {
                return (
                    <tr>
                        <td>{item.id}</td>
                        <td>{item.creative_id}</td>
                        <td>{item.screen}, {item.inventory_id}</td>
                        <td>{item.is_active ? "✅" : "❌"}</td>
                        <td>{item.adId}</td>
                        <td>{formatDateTimeAM(item.created_at)}</td>
                        <td>{formatDateTimeAM(item.updated_at)}</td>
                        <td>
                            <ul>
                                <li>
                                    <Link to={`/update_clients_ads/${item.id}`}>Update</Link>
                                </li>

                                <li>
                                    <button
                                        className="openModalBtn"
                                        onClick={() => {
                                            props.setCurrentId(item.id);
                                            props.setModalOpen(true);
                                            {!item.is_active?props.setCurrentIsActive(false):props.setCurrentIsActive(true)}
                                        }}
                                    >
                                        {!item.is_active?"Activate":"Deactivate"}
                                    </button>

                                </li>
                            </ul>

                        </td>
                    </tr>
                )
            })

            }
        </>
    )

}

function ClientAd() {

    const [clientad, setClientad] = useState();
    const [loggedin, setloggedin] = useState(true);
    const [pages, setPages] = useState();
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [currentIsActive, setCurrentIsActive] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if (typeof ReactSession.get("username") !== "undefined") {
            setloggedin(true);
        }
    })

    useEffect(() => {
        if (loggedin) {
            let access_token = 'Bearer ' + ReactSession.get("access_token");
            axios({
                method: "get",
                url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/list_client_ad?paginate=true",
                headers: {
                    Authorization: access_token
                }
            }).then((response) => {
                console.log(response.data.content);
                setClientad(response.data.content);
                setPages(Math.ceil(response.data.total / 10));
                setLoading(false);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [])

    const handlePageClick = (data) => {
        console.log("data", data);
        let currentPage = data.selected + 1;
        let access_token = 'Bearer ' + ReactSession.get("access_token");
        axios.get(global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + `/list_client_ad?paginate=true&page=${currentPage}&ver=0&lang=hi`,
            { headers: { Authorization: access_token } })
            .then((response) => {
                console.log(response.data.content);
                setClientad(response.data.content);
            })
            .catch((err) => {
                console.error(String(err));
            });
    }

    const handleFormActivate = (bool) => {
        let access_token = 'Bearer ' + ReactSession.get("access_token");
        let data = {"is_active": bool?false:true};
        console.log("data", data);
        axios({
            method: "post",
            url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/edit_client_ad/" + currentId,
            data: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: access_token
            }
        }).then((response) => {
            console.log(response);
            window.location.reload();  
        }).catch((err) => {
            console.log(err);
        })
    }

    const setModalOpen = (bool) => {
		setShowModal(bool);
	}

    const handleModalContinue = () => {
        if(currentIsActive) {
            handleFormActivate(true);
            setModalOpen(false);
        }else {
            handleFormActivate(false);
            setModalOpen(false);
        }
    }

    let msg = currentIsActive?"Are you sure you want to deactivate the client ad": "Are you sure you want to activate the client ad"
    return (
        <div class="container">
            {showModal?<Modal setOpenModal={setModalOpen} handleModalContinue={handleModalContinue} msg={msg} />:<></>}
            {loading ?<Loader /> :
                <>
                    <div class="row">
                        <h2 style={{ "display": "inline-block" }} className="mb-2">Forms</h2>
                        <button className="btn btn-success pull-right mt-5" name="add"><Link to="/add_clients_ads">ADD</Link></button>
                    </div>
                    <div class="row">
                        <table className="table mb-0">
                            <thead className="bg-light">
                                <tr>
                                    <th scope="col" className="border-0">
                                        Id
                                    </th>
                                    <th scope="col" className="border-0">
                                        Creative Id
                                    </th>
                                    <th scope="col" className="border-0">
                                        Inventory
                                    </th>
                                    <th scope="col" className="border-0">
                                        Active
                                    </th>
                                    <th scope="col" className="border-0">
                                        adId
                                    </th>
                                    <th scope="col" className="border-0">
                                        Created At
                                    </th>
                                    <th scope="col" className="border-0">
                                        Updated At
                                    </th>
                                    <th scope="col" className="border-0">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {console.log("client", clientad)}
                                <ClientTable clientad={clientad} setModalOpen={setModalOpen} setCurrentId={setCurrentId} setCurrentIsActive={setCurrentIsActive}/>
                            </tbody>
                        </table>
                        <div>
                            <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                pageCount={pages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>

                    </div>
                </>}
        </div>
    )
}

export default ClientAd
