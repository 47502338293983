import React,{Component} from 'react';
import { ReactSession } from 'react-client-session';
import { Link } from 'react-router-dom';


class Logout extends Component{
	constructor(props) {
	    super(props);

	    this.state = {
			logged_in: false
		}

		if(typeof ReactSession.get("username") !== 'undefined'){
			console.log(ReactSession.get("username"));
			this.state.logged_in= true;
			this.state.username = ReactSession.get("username");
		}
	    this.clickLogout = this.clickLogout.bind(this);
	}
	clickLogout = () => {
		ReactSession.remove('username');
		ReactSession.remove('access_token');
		this.setState({
			logged_in: false,
			username: ''
		});
		console.log("user logged out.")
	}
	render(){
		if(this.state.logged_in){
			return(
				<div>
					<button type="button" className="btn btn-primary" onClick={()=>{this.clickLogout()}}>Logout</button>
				</div>
			);
		}
		else{
			return(
				<div>
					<h2 className='mb-2 $blue-100 bold'>Not Logged In!</h2>
					<button className="btn btn-primary" ><Link className="card-link" to={'/'}>Login</Link></button>
				</div>
			);
		}
	}
}
export default Logout;