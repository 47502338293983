import React, { Component } from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";
import { APP_CONSTANTS } from "../../utils/constants";
import CreateLandingPageFormFields from "./form_fields/create_page_form_fields";
import CreateLandingPageForm from './create_page_form';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import Toast from '../Toast'

class ViewForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form_id: props.match.params.form_id,
            form: {},
            fields: [],
            logged_in: false,
            username: "",
            show_form: false,
            show_formMeta: false,
            total_fields: 0,
            showToast: false,
            toastMsg: ""
        };
        console.log("params", this.props.match.params.form_id);
        if (typeof ReactSession.get("username") !== "undefined") {
            this.state.logged_in = true;
            this.state.username = ReactSession.get("username");
        }
        this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
    }

    componentDidMount() {
        if (this.state.logged_in) this.get_form();
    }

    get_form = () => {
        var access_token = "Bearer " + ReactSession.get("access_token");
        // var data = [];
        axios
            .get(
                global.config.apiGateway.URL +
                APP_CONSTANTS.ad_sales +
                "/get_form_structure/" +
                this.state.form_id,
                {
                    method: "get",
                    headers: { Authorization: access_token },
                }
            )
            .then((response) => {
                const form = response.data.content;
                this.setState({
                    form: form,
                    fields: form.fields,
                    total_fields: form.fields.length,
                });
                console.log("field", response.data);
            })
            .catch((err) => {
                console.error(String(err));
            });
    };

    nextPath(path) {
        this.props.history.push(path);
    }

    renderFormDetails = (form) => (
        <tr>
            <td>{form.id}</td>
            <td>{form.form_name}</td>
            <td>{form.display_title}</td>
            <td>{form.page_id}</td>
            <td>{form.is_active}</td>
            <td>{form.created_at}</td>
            <td>{form.updated_at}</td>
            {/* <td>Actions</td> */}
        </tr>
    );

    handleOnDragEnd(result) {
        if (!result.destination) return;
        console.log(this.state);
        const items = Array.from(this.state.fields);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        console.log(items);
        for (let index = 0; index < items.length; index++) {
            items[index]["position"] = index + 1;
        }

        // updateCharacters(items);
        this.setState({
            fields: items,
        });

        var access_token = "Bearer " + ReactSession.get("access_token");
        console.log("items", items);
        axios({
            method: "post",
            url:
                global.config.apiGateway.URL +
                APP_CONSTANTS.ad_sales +
                "/update_fields_order",
            data: items,
            headers: {
                Authorization: access_token,
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    this.setState({
                        showToast: true,
                        toastMsg: response.data.content
                    })

                    setInterval(() => {
                        this.setState({
                            showToast: false,
                            toastMsg: ""
                        })
                    }, 4000)
                }
            })
            .catch((err) => {
                console.error(String(err));
                // if (err.response.status === 400) {
                alert(err.response.data.content);
                // alert(err.response.data.content.errorMsg);
                // }
            });
    }



    render() {
        if (!this.state.logged_in) {
            return (
                <div>
                    <h5>Not logged in !</h5>
                </div>
            );
        }
        return (
            <div class="container">
                {this.state.showToast ? <Toast msg={this.state.toastMsg} /> : <></>}
                <h2 style={{ display: "inline-block" }} className="my-8">Form Details</h2>
                <div class="row" style={{ display: "flex", justifyContent: "space-around", background: "#e6e0e07a", padding: "10px" }} >
                    <div>
                        <ul>
                            <li>
                                <b>Form Name: </b>
                                {this.state.form.form_name}
                            </li>
                            <li>
                                <b>Title: </b>
                                {this.state.form.display_title}
                            </li>
                            <li>
                                <b>Component Id: </b>
                                {this.state.form.cid}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <b>Button Id: </b>
                                {this.state.form.button_id}
                            </li>
                            <li>
                                <b>CTA Text: </b>
                                {this.state.form.cta_text}
                            </li>
                            <li>
                                <b>CTA Target: </b>
                                {this.state.form.cta_target}
                            </li>
                            <li>
                                <b>CTA Target URL: </b>
                                {this.state.form.cta_target_url}
                            </li>
                            <li>
                                <b>CTA Message</b>
                                {this.state.form.cta_message}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <b>Company: </b>
                                {this.state.form.company}
                            </li>
                            <li>
                                <b>Amount: </b>
                                {this.state.form.amount}
                            </li>
                            <li>
                                <b>Collect Payment: </b>
                                {this.state.form.collect_payment}
                            </li>
                            <li>
                                <b>Product Name: </b>
                                {this.state.form.product_name}
                            </li>
                            <li>
                                <b>Payment Success Message: </b>
                                {this.state.form.payment_success_message}
                            </li>
                            <li>
                                <b>Payment Failture Message: </b>
                                {this.state.form.payment_failure_message}
                            </li>
                            <li>
                                <b>Payment Description: </b>
                                {this.state.form.payment_description}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row my-16">
                </div>
                {this.state.fields.length > 0 && (
                    <div class="panel panel-default" style={{ marginTop: "5vh" }}>
                        <div class="panel-heading">
                            <div className="d-inline">
                                <p>Form Fields</p>
                            </div>
                        </div>
                        <div class="panel-body">
                            <DragDropContext onDragEnd={this.handleOnDragEnd}>
                                <Droppable disabled droppableId="characters">
                                    {(provided) => (
                                        <div
                                            className="characters"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            class="panel-body"
                                        >
                                            {/* {this.renderFields(this.state.fields)} */}

                                            {this.state.fields.map((field, idx) => {
                                                return (
                                                    <Draggable
                                                        disabled
                                                        key={idx}
                                                        draggableId={field.placeholder_text}
                                                        index={idx}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                disabled
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                class="panel panel-default"
                                                            >
                                                                <div class="panel panel-heading">
                                                                    <span>
                                                                        <b>Field ID </b> {field.id}{" "}
                                                                    </span>
                                                                    <span>
                                                                        <b>Position </b> {field.position}
                                                                    </span>

                                                                    {/* add onclick implememtation */}

                                                                </div>
                                                                <div class="panel-body box-border ">
                                                                    <form
                                                                        id={`form-${field.id}`}
                                                                        className="form-horizontal col-md-8"
                                                                        onSubmit={this.handleSubmit}
                                                                    >
                                                                        <div className="form-group">
                                                                            <label
                                                                                className="control-label col-md-3"
                                                                                htmlFor="type"
                                                                            >
                                                                                Field Type:
                                                                            </label>
                                                                            <div className="col-md-9">
                                                                                <select
                                                                                    disabled
                                                                                    required
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="type"
                                                                                    fieldid={field.id}
                                                                                    name="type"
                                                                                    onChange={this.handleChange}
                                                                                    value={field.type}
                                                                                >
                                                                                    <option
                                                                                        value=""
                                                                                        selected
                                                                                        disabled
                                                                                        hidden
                                                                                    >
                                                                                        Select Field Type
                                                                                    </option>
                                                                                    <option
                                                                                        selected={field.type === "number"}
                                                                                        value="number"
                                                                                    >
                                                                                        Number
                                                                                    </option>
                                                                                    <option
                                                                                        selected={field.type === "phone_no"}
                                                                                        value="phone"
                                                                                    >
                                                                                        Phone number
                                                                                    </option>
                                                                                    <option
                                                                                        selected={field.type === "text"}
                                                                                        value="text"
                                                                                    >
                                                                                        Text
                                                                                    </option>
                                                                                    <option
                                                                                        selected={field.type === "select"}
                                                                                        value="select"
                                                                                    >
                                                                                        Select
                                                                                    </option>
                                                                                    <option
                                                                                        selected={field.type === "name"}
                                                                                        value="name"
                                                                                    >
                                                                                        Name
                                                                                    </option>


                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label
                                                                                className="control-label col-md-3"
                                                                                htmlFor="question"
                                                                            >
                                                                                Question Text
                                                                            </label>
                                                                            <div className="col-md-9">
                                                                                <input
                                                                                    disabled
                                                                                    required
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="question_text"
                                                                                    fieldid={field.id}
                                                                                    placeholder="Enter Question Text"
                                                                                    name="question_text"
                                                                                    onChange={this.handleChange}
                                                                                    value={field.question_text}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label
                                                                                className="control-label col-md-3"
                                                                                htmlFor="placeholder_text"
                                                                            >
                                                                                Placeholder Text
                                                                            </label>
                                                                            <div className="col-md-9">
                                                                                <input
                                                                                    disabled
                                                                                    required
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="placeholder_text"
                                                                                    fieldid={field.id}
                                                                                    placeholder="Enter Placeholder Text"
                                                                                    name="placeholder_text"
                                                                                    onChange={this.handleChange}
                                                                                    value={field.placeholder_text}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label
                                                                                className="control-label col-md-3"
                                                                                htmlFor="text"
                                                                            >
                                                                                CId:
                                                                            </label>
                                                                            <div className="col-md-9">
                                                                                <input
                                                                                    disabled
                                                                                    required
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="cid"
                                                                                    fieldid={field.id}
                                                                                    placeholder="Enter component id"
                                                                                    name="cid"
                                                                                    onChange={this.handleChange}
                                                                                    value={field.cid}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label
                                                                                className="control-label col-md-3"
                                                                                htmlFor="is_required"
                                                                            >
                                                                                Is Required(Mandatory)
                                                                            </label>
                                                                            <div className="col-md-9">
                                                                                {console.log("required", field.is_required)}
                                                                                <input
                                                                                    disabled
                                                                                    required
                                                                                    type="checkbox"
                                                                                    id="is_required"
                                                                                    fieldid={field.id}
                                                                                    placeholder="Is Required"
                                                                                    name="is_required"
                                                                                    onChange={this.handleChange}
                                                                                    defaultChecked={field.is_required}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                                <div class="panel-footer">

                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default ViewForm;
