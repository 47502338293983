import React from "react";
import "./Modal.css";

function Modal(props) {

    return (
        <div className="modalBackground">
            <div className="modalContainer">
                <div className="titleCloseBtn">
                    <button
                        onClick={() => {
                            props.setOpenModal(false);
                        }}
                    >
                        X
                    </button>
                </div>
                <div className="title">
                    <h1>{props.msg}</h1>
                    <hr color="gray" />
                </div>
                <div className="footer">
                    <button
                        onClick={() => {
                            props.setOpenModal(false);
                        }}
                        id="cancelBtn"
                    >
                        Cancel
                    </button>
                    <button onClick={() => props.handleModalContinue(true)}>Continue</button>
                </div>
            </div>
        </div>
    );
}

export default Modal;