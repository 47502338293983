import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ReactSession } from 'react-client-session';
import { APP_CONSTANTS } from 'utils/constants';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from 'components/Loader';

function AddCreative(props) {
    //const [fields, setFields] = useState({});
    const [name, setName] = useState("");
    const [creative_url, setCreative] = useState("");
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState('');
    const [client_id, setclientid] = useState("");
    const [height, setheight] = useState("");
    const [width, setwidth] = useState("");
    const [targettype, settargettype] = useState("");
    const [target_url, settargeturl] = useState("");
    const [id, setId] = useState("");
    const [type, setType] = useState();
    const [t, setT] = useState();
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(true);

    const getClients = () => {
        var access_token = "Bearer " + ReactSession.get("access_token");
        axios.get(
            global.config.apiGateway.URL +
            APP_CONSTANTS.ad_sales + "/list_all_clients",
            {
                method: "get",
                headers: { Authorization: access_token },
            }
        ).then((response) => {
            console.log("clients", response.data.content);
            setClients(response.data.content)
            setLoading(false);
        }).catch((err) => {
            console.log("error", err);
        })
    }

    useEffect(() => {
    }, [])


    useEffect(() => {
        let creative = location.state.creative;
        console.log("it has t", location.state.t)
        setT(location.state.t);
        if (location.state.t == 'update') {
            console.log("update part")
            setName(creative.name);
            setCreative(creative.creative_url)
            setclientid(creative.client_id);
            setheight(creative.height);
            setwidth(creative.width);
            settargettype(creative.targettype);
            settargeturl(creative.target_url);
            setType(creative.type);
            setId(creative.id);
        }
        getClients();
    }, [])

    useEffect(() => {
        if (location.state.t == 'update') {
            clients.forEach((item) => {
                if (item.id == client_id) {
                    setClient(item.client_name);
                    return;
                }
            })

        }
    }, [clients])

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let access_token = 'Bearer ' + ReactSession.get("access_token");
        console.log(access_token);
        if (t == "add") {
            console.log('add');
            var data = new FormData();
            data.append('name', name);
            data.append('creative_url', creative_url);
            data.append('type', type);
            data.append('client_id', client_id);
            data.append('width', width);
            data.append('height', height);
            data.append('targettype', targettype);
            data.append('target_url', target_url);
            axios({
                method: "post",
                url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/add_creative",
                data: data,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: access_token
                }
            }).then((response) => {
                console.log(response);
                setLoading(false);
                history.push('/creative')
            }).catch((err) => {
                console.log(err);
                setLoading(false);
            })
        } else {
            let data = new FormData();
            data.append("name", name);
            data.append('creative_url', creative_url);
            data.append('type', type);
            data.append('client_id', client_id);
            data.append('width', width);
            data.append('height', height);
            data.append('targettype', targettype);
            data.append('target_url', target_url);
            console.log(id);
            axios({
                method: "post",
                url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/edit_creative/" + id,
                data: data,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: access_token
                }
            }).then((response) => {
                console.log(response);
                setLoading(false);
                history.push('/creative')
            }).catch((err) => {
                console.log(err);
                setLoading(false)
            })
        }

    }

    const handleClients = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let clientId = optionElement.getAttribute('id');
        let value = optionElement.getAttribute('value');
        setclientid(clientId);
        setClient(value);
    }

    return (
        <div className="container">
            {loading ? <Loader /> :
            <form className="form-horizontal col-md-8" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label className="control-label col-md-3">Name: </label>
                    <div className="col-md-9">
                        <input required type="text" className="form-control" id="name" name="name" onChange={(e) => setName(e.target.value)} value={name} /><br />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-3">Creative URL: </label>
                    <div className="col-md-9">
                        <input required type="text" className="form-control" id="creative_url" name="creative_url" onChange={(e) => setCreative(e.target.value)} value={creative_url} /><br />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-3">Type: </label>
                    <div className="col-md-9">
                        <select required className="form-control" value={type} onChange={(e) => setType(e.target.value)}>
                            <option value="" disabled selected hidden>Select</option>
                            <option value="Image">Image</option>
                            <option value="Video">Video</option>
                        </select>
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-3">Client ID {": "}</label>
                    <div className="col-md-9">
                        <select required className="form-control" onChange={handleClients} value={client}>
                            <option value="" disabled selected hidden>Select</option>
                            {clients?.map((client) => {
                                return (<option id={client.id} value={client.client_name}>{client.client_name} </option>)
                            })}
                        </select>
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-3">Width: </label>
                    <div className="col-md-9">
                        <input required type="text" className="form-control" id="width" name="width" onChange={(e) => setwidth(e.target.value)} value={width} /><br />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-3">Height: </label>
                    <div className="col-md-9">
                        <input required type="text" className="form-control" id="height" name="height" onChange={(e) => setheight(e.target.value)} value={height} /><br />
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-3">Target Type: </label>
                    <div className="col-md-9">
                        <select required className="form-control" value={targettype} onChange={(e) => settargettype(e.target.value)}>
                            <option value="" disabled selected hidden>Select</option>
                            <option value="Web">Web</option>
                            <option value="App">App</option>
                        </select>
                    </div>
                </div>
                <div className="form-group">
                    <label className="control-label col-md-3">Target URL: </label>
                    <div className="col-md-9">
                        <input required type="text" className="form-control" id="target_url" name="target_url" onChange={(e) => settargeturl(e.target.value)} value={target_url} /><br />
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-md-offset-2 col-md-10">
                        {t == "add" ? <input type="submit" className="btn btn-default" style={{ float: "right" }} value="Submit"></input> :
                            <input type="submit" className="btn btn-default" style={{ float: "right" }} value="Update"></input>}
                    </div>
                </div>

            </form>}
        </div>
    )
}

export default AddCreative
