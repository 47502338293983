// function to add pad if number is less than 10
export const pad = (num) => {
  return num < 10 ? '0' + num : num
}

export const formatDateTime = (dateTime) => {
    if(!dateTime) return ''
    const date = new Date(dateTime)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return `${pad(day)}/${pad(month)}/${year} ${hours}:${pad(minutes)}:${pad(seconds)}`
}

export const formatDateTimeAM = (dateTime) => {
    if(!dateTime) return ''
    const date = new Date(dateTime)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    let hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12
    return `${pad(day)}/${pad(month)}/${year} ${hours}:${pad(minutes)}:${pad(seconds)} ${ampm}`
}



