import React, { Component } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import { APP_CONSTANTS } from '../../utils/constants';
import Toast from '../Toast'

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			phone_no: '',
			otp: '',
			name: '',
			otp_sent: false,
			logged_in: false,
			showToast: false,
			toastMsg: ""
		}
		// ReactSession.set("test", "aaa");

		if (typeof ReactSession.get("username") !== 'undefined' && typeof ReactSession.get("access_token") !== 'undefined') {
			console.log(ReactSession.get("username"));
			this.state.logged_in = true;
			this.state.username = ReactSession.get("username");
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmitNumber = this.handleSubmitNumber.bind(this);
		this.handleSubmitOtp = this.handleSubmitOtp.bind(this);
	}

	handleChange = (e) => {
		let nam = e.target.name;
		let val = e.target.value;

		this.setState({
			[nam]: val
		});
	}
	handleSubmitNumber = (e) => {
		e.preventDefault();
		var formdata = new FormData();
		formdata.append('name', this.state.name);
		formdata.append('phone_no', this.state.phone_no);
		axios({
			method: "post",
			url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/user/otp?lang=hi",
			data: formdata,
			headers: { "Content-Type": "multipart/form-data" },
		}).then((response) => {
			if (response.status === 200) {
				this.setState({ 'otp_sent': true });
				this.setState({
					showToast: true,
					toastMsg: "OTP sent!"
				})

				setTimeout(() => {
					this.setState({
						showToast: false,
						toastMsg: "",
					})
				}, 4000)
			}
		})
			.catch((err) => {
				console.error("hello", err.status);
				this.setState({
					showToast: true,
					toastMsg: "access not allowed"
				})

				setTimeout(() => {
					this.setState({
						showToast: false,
						toastMsg: "",
					})
				}, 4000)
			});
	}
	handleSubmitOtp = (e) => {
		e.preventDefault();
		var formdata = new FormData();
		formdata.append('name', this.state.name);
		formdata.append('phone_no', this.state.phone_no);
		formdata.append('otp', this.state.otp);
		formdata.append('lat', '28.7041');
		formdata.append('lon', '77.1025');

		axios({
			method: "post",
			url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/user/login?app_id=4",
			data: formdata,
			headers: { "Content-Type": "multipart/form-data" },
		}).then((response) => {
			// console.log(response.status,response.data , response.data.content,typeof response.status)
			if (response.status === 200) {
				// console.log("login response",JSON.stringify(response.data))
				ReactSession.set("access_token", response.data.content.access_token);
				ReactSession.set("username", this.state.name);
				this.setState({
					username: this.state.name,
					logged_in: true,
					phone_no: '',
					otp: '',
					name: '',
					otp_sent: false,
				});
				this.setState({
					showToast: true,
					toastMsg: "Logged in successfully!"
				})

				setTimeout(() => {
					this.setState({
						showToast: false,
						toastMsg: "",
					})
				}, 4000)
				window.location.reload(false);
			}
			else {
				console.log("enter")
				if (response.data.status === "error") {
					this.setState({
						showToast: true,
						toastMsg: response.data.errorMsg
					})

					setTimeout(() => {
						this.setState({
							showToast: false,
							toastMsg: "",
						})
					}, 4000)
				}
			}
		})
			.catch((err) => {
				console.error(String(err));
				this.setState({
					showToast: true,
					toastMsg: "OTP entered is not right"
				})

				setTimeout(() => {
					this.setState({
						showToast: false,
						toastMsg: "",
					})
				}, 4000)
			});
	}

	render() {

		const divStyle = {
			marginTop: "10%",
			background: "#e0d7d7a6",
			padding: "20px",
			borderRadius: "10px",
			border: "1px solid gray"
		}
		if (this.state.logged_in) {
			return (
				<div>
					<h2>logged in as {this.state.username}</h2>
				</div>
			);
		}
		if (this.state.otp_sent) {
			return (
				<div>
					{this.state.showToast ? <Toast msg={this.state.toastMsg} /> : <></>}
					<div className="col-md-offset-3 col-md-6 ms-3 me-3" style={divStyle}>
						<h5>OTP sent on <b>{this.state.phone_no}</b></h5>
						<form onSubmit={this.handleSubmitOtp} className='mt-6'>
							<div className="form-group">
								<label htmlFor="otp">OTP: </label>
								<input required type="number" name="otp" id="otp" className="form-control" onChange={this.handleChange} value={this.state.otp} />
								
							</div>
							<div className="form-group">
								<button type="submit" id="submit_otp" className="form-control btn-primary">Submit</button>
							</div>

							<button onClick={() => window.location.reload()}>Resend OTP</button>
						</form>
					</div>
				</div>

			);
		}
		else {
			return (
				<>
					{this.state.showToast ? <Toast msg={this.state.toastMsg} /> : <></>}
					<div className="col-md-offset-3 col-md-6 ms-3 me-3" style={divStyle}>
						<form onSubmit={this.handleSubmitNumber}>
							<div className="form-group">
								<label htmlFor="task">Name: </label>
								<input required autoComplete="on" type="text" name="name" id="task" className="form-control" onChange={this.handleChange} value={this.state.name} />
							</div>
							<div className="form-group">
								<label htmlFor="phone_no">Phone Number: </label>
								<input required autoComplete="on" type="number" name="phone_no" id="phone_no" className="form-control" onChange={this.handleChange} value={this.state.phone_no} />
							</div>

							<div className="form-group">
								<button type="submit" id="submit_phone_no" className="form-control btn-primary">Submit</button>
							</div>
						</form>
					</div>
				</>
			);
		}
	}
}
export default Login;