import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import { APP_CONSTANTS } from '../../utils/constants';
import { Link, useHistory } from 'react-router-dom';
import Loader from '../Loader';

function UpdateClientAd(props) {
    const [clients, setClients] = useState([]);
    const [clientId, setClientId] = useState("");
    const [client, setClient] = useState("");
    const [inventory, setInventory] = useState([]);
    const [inventoryId, setInventoryId] = useState("");
    const [iItem, setiItem] = useState("");
    const [creative, setCreative] = useState([]);
    const [creativeId, setCreativeId] = useState("");
    const [citem, setcItem] = useState("");
    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [subDistricts, setSubDistricts] = useState([]);
    const [villages, setVillages] = useState([]);
    const [adname, setadname] = useState("");
    const [loading, setLoading] = useState();
    let id = props.match.params.id;
    const history = useHistory();
    var access_token = "Bearer " + ReactSession.get("access_token");
    const getClients = (id) => {
        axios.get(
            global.config.apiGateway.URL +
            APP_CONSTANTS.ad_sales + "/list_all_clients",
            {
                method: "get",
                headers: { Authorization: access_token },
            }
        ).then((response) => {
            setClients(response.data.content);
        }).catch((err) => {
            console.log("error", err);
        })
    }

    const getInventory = (id) => {
        axios.get(
            global.config.apiGateway.URL +
            APP_CONSTANTS.ad_sales + "/list_inventory",
            {
                method: "get",
                headers: { Authorization: access_token },
            }
        ).then((response) => {
            //console.log("inventory", response.data.content);
            setInventory(response.data.content);
        }).catch((err) => {
            console.log("error", err);
        })
    }

    const getCreative = (id) => {
        axios.get(
            global.config.apiGateway.URL +
            APP_CONSTANTS.ad_sales + "/list_creative",
            {
                method: "get",
                headers: { Authorization: access_token },
            }
        ).then((response) => {
            //console.log(" creative", response.data.content);
            setCreative(response.data.content);

        }).catch((err) => {
            console.log("error", err);
        })
    }
    useEffect(() => {
        axios.get(
            global.config.apiGateway.URL +
            APP_CONSTANTS.ad_sales + "/client_ad/" + id,
            {
                method: "get",
                headers: { Authorization: access_token },
            }
        ).then((response) => {
            let data = response.data.content;
            console.log(" crea", response.data.content);
            setStates(data.geo.states);
            setDistricts(data.geo.districts);
            setSubDistricts(data.geo.subdivisions);
            setVillages(data.geo.villages);
            setadname(data.adId)
            setClientId(data.client_id);
            getClients();
            setInventoryId(data.inventory_id);
            getInventory();
            setCreativeId(data.creative_id);
            getCreative();
        }).catch((err) => {
            console.log("error", err);
        })
    }, [])

    useEffect(() => {
        clients.forEach((item) => {
            if (item.id == clientId) {
                setClient(item.client_name);
                return;
            }
        })
        inventory.find((item) => {
            if (item.id == inventoryId) {
                setiItem(item.key);
            }
        })

        creative.find((item) => {
            if (item.id == creativeId) {
                setcItem(item.name)
            }
        })

    }, [clients, inventory, creative])


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let data = {
            ad_name: adname,
            client_id: clientId,
            inventory_id: inventoryId,
            creative_id: creativeId,
            states: states,
            districts: districts,
            subdivisions: subDistricts,
            villages: villages
        }
        axios({
            method: "post",
            url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/edit_client_ad/" + id,
            data: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: access_token
            }
        }).then((response) => {
            console.log(response);
            setLoading(false);
            history.push("/clients_ads")
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }

    const handleClients = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let clientId = optionElement.getAttribute('id');
        let value = optionElement.getAttribute('value')
        setClientId(clientId);
        setClient(value);
    }

    const handleInventory = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let inventoryId = optionElement.getAttribute('id');
        let value = optionElement.getAttribute('value')
        setInventoryId(inventoryId);
        setiItem(value)
    }
    const handleCreative = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let creativeId = optionElement.getAttribute('id');
        let value = optionElement.getAttribute('value');
        setCreativeId(creativeId);
        setcItem(value);
    }

    return (
        <div className="container">
            {loading ?<Loader />:
                <form className="form-horizontal col-md-8" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label className="control-label col-md-3">Ad Name: </label>
                        <div className="col-md-9">
                            <input type="text" className="form-control" id="ad_name" placeholder="Enter Ad name" onChange={(e) => setadname(e.target.value)} value={adname} /><br />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-3">Client ID: </label>
                        <div className="col-md-9">
                            <select required className="form-control" onChange={(e) => handleClients(e)} value={client}>
                                <option>Select</option>
                                {clients?.map((client) => {
                                    return (<option id={client.id} value={client.client_name}>{client.client_name} </option>)
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-3">Inventory ID: </label>
                        <div className="col-md-9">
                            <select required className="form-control" onChange={(e) => handleInventory(e)} value={iItem}>
                                <option>Select</option>
                                {inventory?.map((item) => {
                                    return (<option id={item.id} value={item.key}>{item.key} </option>)
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="control-label col-md-3">Creative ID: </label>
                        <div className="col-md-9">
                            <select required className="form-control" onChange={(e) => handleCreative(e)} value={citem}>
                                <option>Select</option>
                                {creative?.map((item) => {
                                    return (<option id={item.id} value={item.name}>{item.name} </option>)
                                })}
                            </select>
                        </div>
                    </div>

                    <div style={{ paddingLeft: '10%' }}>
                        <p>States: {String(states)}</p><br />
                        <p>Districts: {String(districts)}</p><br />
                        <p>Sub divisions: {String(subDistricts)}</p><br />
                        <p>Villages: {String(villages)}</p>
                    </div>

                    <div className="form-group">
                        <div className="col-md-offset-2 col-md-10">
                            <input type="submit" className="btn btn-default" style={{ float: "right" }} name="submit" value="Update"></input>
                        </div>
                    </div>

                </form>}
        </div>
    )
}

export default UpdateClientAd
