import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import { APP_CONSTANTS } from '../../../utils/constants';

class CreatePageFormFields extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cid: "",
			redirect: false,
			question_text: '',
			type: '',
			placeholder_text: '',
			validation_required: true,
			is_required: true,
			position: this.props.data.position,
			form_id: this.props.data.form_id,
			showToast: false
		};
		console.log("props data", this.props.data);
		console.log("position", this.state.position, this.state.form_id);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (e) => {
		let nam = e.target.name;
		let val = e.target.value;

		this.setState({
			[nam]: val
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();
		var access_token = 'Bearer ' + ReactSession.get("access_token");
		const formdata = new FormData();
		let cid = this.state.cid || "";
		cid = cid.toLowerCase();
		cid = cid.replaceAll(" ", "_");
		formdata.append('validation_required', true);
		formdata.append('position', this.state.position)
		formdata.append('cid', cid);
		formdata.append("question_text", this.state.question_text);
		formdata.append("type", this.state.type);
		formdata.append("placeholder_text", this.state.placeholder_text);
		formdata.append("is_required", this.state.is_required);
		axios({
			method: "post",
			url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/add_form_field/" + this.state.form_id,
			data: formdata,
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: access_token
			},
		}).then((response) => {
			console.log(response);
			if (response.status === 200) {
				console.log("response", response.data.content)
				window.location.reload();
			}
		})
			.catch((err) => {
				console.error(String(err));
				if (err.response.status === 400) {
					console.log(err.response)
				}
			});
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to={this.state.redirect} />
		}
		return (
			<>
			{}
				<div className="col-md-12" style={{ "margin-bottom": "30px", marginTop: "6%", border: "1px solid lightgrey", borderRadius: "10px" }}>
					<div className="col-md-4 row mt-6">
						<div className="col-md-12">
							<h3>Add Fields to Form</h3>
							<small> Placeholder text is treated as question/label text</small>
						</div>
					</div>
					<form id="create_field" className="form-horizontal col-md-8 mt-6" onSubmit={this.handleSubmit}>
						<div className="form-group">
							<label className="control-label col-md-3" htmlFor="type">Field Type*</label>
							<div className="col-md-9">
								<select required type="text" className="form-control" id="type" name="type" onChange={this.handleChange} value={this.state.type}>
									<option value="" selected disabled hidden>Select Field Type</option>
									<option value="number" >number</option>
									<option value="name" >Name</option>
									<option value="phone_no" >Phone number</option>
									<option value="text" >text</option>
									<option value="select">select</option>
								</select>
							</div>
						</div>
						<div className="form-group">
							<label className="control-label col-md-3" htmlFor="question">Question*</label>
							<div className="col-md-9">
								<input required type="text" className="form-control" id="question_text" placeholder="Enter Question Text" name="question_text" onChange={this.handleChange} value={this.state.question_text} />
							</div>
						</div>
						<div className="form-group">
							<label className="control-label col-md-3" htmlFor="placeholder_text">Placeholder Text*</label>
							<div className="col-md-9">
								<input required type="text" className="form-control" id="placeholder_text" placeholder="Enter Placeholder Text" name="placeholder_text" onChange={this.handleChange} value={this.state.placeholder_text} />
							</div>
						</div>

						<div className="form-group">
							<label className="control-label col-md-3" htmlFor="placeholder_text">CId*</label>
							<div className="col-md-9">
								<input required type="text" className="form-control" id="cid" placeholder="Enter Component Id" name="cid" onChange={this.handleChange} value={this.state.cid} />
							</div>
						</div>
						<div className="form-group">
							<label className="control-label col-md-3" htmlFor="is_required">Is Required(Mandatory)</label>
							<div className="col-md-9">
								<input required type="checkbox" checked id="is_required" placeholder="Is Required" name="is_required" onChange={this.handleChange} value={this.state.is_required} />
							</div>
						</div>
						<div className="form-group">
							<div className="col-md-offset-2 col-md-10">
								<button type="submit" className="btn btn-default" style={{ float: "right" }}>Submit</button>
							</div>
						</div>
					</form>
				</div>
			</>

		);
	}
}

export default CreatePageFormFields;