import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './config';
global.config = config;
// import ReactLogger from 'react-terminal-logger/console-logger';

// ReactLogger.start(
//     ["log", "error", "info", "warn", "logr"], //You can specify what information to show in the terminal
//     true, //Save logs to a file. Your logs will be saved in directory react-logger-logs. Default: false
//     false, //Show only message in terminal. Ignores the value of stacktrace_hide. Default: false
//     1234, //Port on which the logger is running. Default: 1234
//     true //Hide stacktrace. Default: false
// );

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
