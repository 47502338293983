import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactSession } from "react-client-session";
import axios from "axios";
import { APP_CONSTANTS } from "../../../utils/constants";
import FieldOptionsForm from "./field_options_form";
import Toast from '../../Toast'
import Loader from "../../Loader";
class FieldOptionsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field_id: props.match.params.field_id,
      field: {},
      options: [],
      position: 0,
      option_text: "",
      logged_in: false,
      username: "",
      show_form: false,
      total_fields: 0,
      loading: true,
      showToast: false,
      toastMsg: "",
    };

    console.log(this.props.match.params.form_id);
    if (typeof ReactSession.get("username") !== "undefined") {
      this.state.logged_in = true;
      this.state.username = ReactSession.get("username");
    }

    this.hide_or_show_form = this.hide_or_show_form.bind(this);
    this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
  }

  componentDidMount() {
    if (this.state.logged_in) this.get_field();
  }

  get_field = () => {
    var access_token = "Bearer " + ReactSession.get("access_token");
    // var data = [];
    axios
      .get(
        global.config.apiGateway.URL +
        APP_CONSTANTS.ad_sales +
        "/get_form_field/" +
        this.state.field_id,
        {
          method: "get",
          headers: { Authorization: access_token },
        }
      )
      .then((response) => {
        const field = response.data.content;
        const options = field.options;
        console.log("options", options);
        this.setState({
          options: options,
          total_fields: options.length,
          field: field,
        });
        console.log(response.data);
        console.log(this.state.option_text);
        console.log(this.state.position);
        this.setState({ loading: false })
      })
      .catch((err) => {
        this.setState({ loading: false })
        console.error(String(err));
      });
  };

  hide_or_show_form = () => {
    this.setState({ show_form: !this.state.show_form });
  };

  renderFormDetails = (form) => (
    <tr>
      <td>{form.id}</td>
      <td>{form.form_name}</td>
      <td>{form.display_title}</td>
      <td>{form.page_id}</td>
      <td>{form.is_active}</td>
      <td>{form.created_at}</td>
      <td>{form.updated_at}</td>
    </tr>
  );

  handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(this.state.options);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    for (let index = 0; index < items.length; index++) {
      items[index]["position"] = index + 1;
    }
    this.setState({
      form: items,
    });

    var access_token = "Bearer " + ReactSession.get("access_token");
    console.log("items", items);
    axios({
      method: "post",
      url:
        global.config.apiGateway.URL +
        APP_CONSTANTS.ad_sales +
        "/reorder_form_field_options",
      data: items,
      headers: {
        Authorization: access_token,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            showToast: true,
            toastMsg: response.data.content
          })

          setTimeout(() => {
            this.setState({
              showToast: false,
              toastMsg: ""
            })
          }, 4000)

          window.location.reload();
        }
      })
      .catch((err) => {
        console.error(String(err));
      });
  }
  handleDeleteOption = (id) => {
    var access_token = "Bearer " + ReactSession.get("access_token");
    axios(
      {
        method: "post",
        url:
          global.config.apiGateway.URL +
          APP_CONSTANTS.ad_sales +
          "/delete_form_field_option/" + id,
        headers: {
          Authorization: access_token,
        },
      }
    ).then((response) => {
      console.log("field option deleted");
      window.location.reload();
    }).catch((err) => {
      console.log(err);
      alert(err.response.data.content);
    })

  }

  handleChange = (e) => {
    let id = e.target.getAttribute('fieldid');
    let value = e.target.value;
    let newForm = [...this.state.options];
    for (let i = 0; i < newForm.length; i++) {
      let field = newForm[i];
      if (field.form_field_option_id == id) {
        console.log(true);
        field["option_text"] = value;
        break;
      }
    }
    console.log("new", newForm);
    this.setState({
      form: newForm
    })
  }

  handleUpdate = (e) => {
    var access_token = "Bearer " + ReactSession.get("access_token");
    let obj = this.state.options.find((obj) => {
      return obj.form_field_option_id == e.target.id;
    })
    console.log(obj);
    let formdata = new FormData();
    formdata.append("option_text", obj.option_text);
    formdata.append("position", obj.position);
    axios({
      method: "post",
      url:
        global.config.apiGateway.URL +
        APP_CONSTANTS.ad_sales +
        "/update_form_field_option/" +
        e.target.id,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: access_token,
      },
    }).then((response) => {
      console.log(response);
      window.location.reload();
    }).catch((err) => {
      console.log(err);
    })
  }
  render() {
    if (!this.state.logged_in) {
      return (
        <div>
          <h5>Not logged in !</h5>
        </div>
      );
    }
    return (
      <>
        {this.state.showToast ? <Toast msg={this.state.toastMsg} /> : <></>}
        {this.state.loading ?<Loader />:
          <div class="container">
            <div class="row">
              <h2 style={{ display: "inline-block" }} className="my-8">
                <ul>
                  <li> <b>Field Id:</b>
                    {this.state.field_id}
                  </li>
                  <li>
                    <b>Field Placeholder:</b>
                    {this.state.field.placeholder_text}
                  </li>
                  <li>
                    <b>Field type:</b>
                    {this.state.field.type}
                  </li>
                  <li>
                    <b>Question Text:</b>
                    {this.state.field.question_text}
                  </li>
                </ul>
              </h2>
              <button
                className="btn btn-success pull-right mt-5"
                onClick={this.hide_or_show_form}
                type="submit"
              >
                Add Field Options
              </button>
            </div>
            {this.state.show_form && (
              <FieldOptionsForm
                data={{
                  position: this.state.total_fields + 1,
                  form_id: this.state.field_id,
                }}
              />
            )}

            {this.state.options.length > 0 && (
              <div class="panel panel-default" style={{ marginTop: "17vh" }}>
                <div class="panel-heading">
                  <div className="d-inline">
                    <p>Field Options</p>
                  </div>
                </div>
                <div class="panel-body">
                  <DragDropContext onDragEnd={this.handleOnDragEnd}>
                    <Droppable disabled droppableId="characters">
                      {(provided) => (
                        <div
                          className="characters"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          class="panel-body"
                        >
                          {this.state.options.map((field, idx) => {
                            return (
                              <Draggable
                                disabled
                                key={field.form_field_option_id}
                                draggableId={String(field.form_field_option_id)}
                                index={idx}
                              >
                                {(provided) => (
                                  <div
                                    disabled
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    class="panel panel-default"
                                  >
                                    <div class="panel panel-heading">
                                      <span>
                                        <b>Field Option ID </b>{" "}
                                        {field.form_field_option_id}{" "}
                                      </span>
                                      <span>
                                        <b>Position </b> {idx + 1}
                                      </span>

                                      {!this.state.options.is_active && (
                                        <button
                                          type="button"
                                          class="float-right btn btn-sm btn-primary"
                                          onClick={() => this.handleDeleteOption(field.form_field_option_id)}
                                        >
                                          <i class="fas fa-trash"> Delete</i>
                                        </button>
                                      )}
                                      {!this.state.options.is_active && (
                                        <button
                                          type="button"
                                          class="float-right btn btn-sm btn-primary mx-2"
                                          id={field.form_field_option_id}
                                          onClick={this.handleUpdate}
                                        >
                                          Update
                                        </button>
                                      )}
                                    </div>
                                    <div class="panel-body box-border ">
                                      <form
                                        id={`form-${field.id}`}
                                        className="form-horizontal col-md-8"
                                        onSubmit={this.handleSubmit}
                                      >
                                        <div className="form-group">
                                          <label
                                            className="control-label col-md-3"
                                            htmlFor="field-name"
                                          >
                                            Field Text
                                          </label>
                                          <div className="col-md-9">
                                            <input
                                              disabled={this.state.options.is_active}
                                              fieldid={field.form_field_option_id}
                                              required
                                              type="text"
                                              className="form-control"
                                              id="question_text"
                                              placeholder="Enter Question Text"
                                              name="question_text"

                                              onChange={this.handleChange}
                                              value={field.option_text}
                                            />
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                    <div class="panel-footer">
                                      {field.type === "select" && (
                                        <div>
                                          <button
                                            type="button"
                                            class="btn btn-sm btn-primary"
                                          >
                                            Show Options
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            )}
          </div>}

      </>
    );
  }
}

export default FieldOptionsDetail;
