import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import { APP_CONSTANTS } from '../../utils/constants';
import Loader from '../Loader';

function ExportLead(props) {
    const [startdate, setStartdate] = useState("");
    const [enddate, setEnddate] = useState("");
    const [loading, setLoading] = useState(false);
    let id = props.match.params.id;
    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const fileName = `export_form_${id}_sd_${startdate}_ed_${enddate}.csv`;
        console.log(startdate + " " + enddate);
        var url = global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + `/page_leads_with_date/${id}/?start_date=${startdate}&end_date=${enddate}`;
        axios({
            url: url, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }
    return (
        <div>
            {loading ? <Loader /> :
                <form className="form-horizontal col-md-8" onSubmit={(e) => handleSubmit(e)}>
                    <div className="form-group">
                        <label className="control-label col-md-3">Start Date: </label>
                        <div className="col-md-9">
                            <input required type="date" placeholder="Enter Start Date" className="form-control" id="start_date" name="start_date" value={startdate} onChange={(e) => setStartdate(e.target.value)} /><br />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="control-label col-md-3">End Date: </label>
                        <div className="col-md-9">
                            <input required type="date" className="form-control" id="end_date" name="end_date" value={enddate} onChange={(e) => setEnddate(e.target.value)} /><br />
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="col-md-offset-2 col-md-10">
                            <input type="submit" className="btn btn-default" style={{ float: "right" }} value="Submit"></input>
                        </div>
                    </div>
                </form>}
        </div>
    )
}

export default ExportLead
