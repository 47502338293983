import React from "react";
import { ReactSession } from "react-client-session";
import { Link, NavLink, withRouter } from "react-router-dom";
import { APP_CONSTANTS } from '../utils/constants';
const Navbar = (props) => {
  const handleUpload = (e) => {
    window.open(global.config.apiGateway.URL + "/flask-admin/image_upload", '_blank', 'noopener,noreferrer')
  }
  return (
    <nav className="navbar navbar-default navbar-fixed-top">
      <div className="container-fluid">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target="#myNavbar"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>

          <div className="nav navbar-nav navbar-left">
            <Link className="navbar-brand" to="/">
              Krishi Network CMS
            </Link>
          </div>
        </div>

        <div className="collapse navbar-collapse" id="myNavbar">
          <ul className="nav navbar-nav navbar-right">
          {ReactSession.get("username") &&
            <li className="nav-item dropdown">
              <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Landing Page
                <span class="caret"></span>
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li className="dropdown-item">
                  <NavLink to="/forms">Forms</NavLink>
                </li>
                <li className="dropdown-item">
                  <NavLink to="/list_landing_pages">Pages</NavLink>
                </li>
              </div>
            </li>}
            {ReactSession.get("username") &&
              <li className="nav-item dropdown">
                <a classname="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Ad Banner
                  <span class="caret"></span>
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li className="dropdown-item">
                    <NavLink to="/inventory">Inventory</NavLink>
                  </li>
                  <li className="dropdown-item">
                    <NavLink to="/creative">Creative</NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink to="/clients_ads">Clients Ad</NavLink>
                  </li>
                </div>
              </li>}
            {ReactSession.get("username") &&
              <li class="nav-item">
                <NavLink to="/list_clients">Clients</NavLink>
              </li>
            }
            {ReactSession.get("username") && (
              <li class="nav-item">
                <NavLink to="/">{ReactSession.get("username")}</NavLink>
              </li>
            )}
            {ReactSession.get("username") && (
            <li class="nav-item">
              <button style={{ marginTop: "17%" }} onClick={handleUpload}>Upload Image</button>
            </li>)}
            {ReactSession.get("username") ? (
              <li class="nav-item">
                <NavLink to="/logout">logout</NavLink>
              </li>
            ) : (
              <li class="nav-item">
                <NavLink to="/login" onClick={() => window.location.reload()}>login</NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Navbar);
