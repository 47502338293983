import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import { APP_CONSTANTS } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import Loader from '../Loader';
import { formatDateTimeAM } from 'utils/datetime';

function CreativeTable(props) {
    const history = useHistory();
    const handleUpdate = (e, item) => {
        history.push({
            pathname: "/add_creative",
            state: {
                t: "update",
                creative: item,
            }
        })
    }
    return (
        <>
            {props.creative.map((item, idx) => {
                return (
                    <tr>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.type}</td>
                        <td>{formatDateTimeAM(item.created_at)}</td>
                        <td>{formatDateTimeAM(item.updated_at)}</td>
                        <td onClick={(e) => handleUpdate(e, item)}>
                            Update
                        </td>
                    </tr>
                )
            })

            }
        </>
    )

}

function Creative() {
    const [creative, setCreative] = useState(null);
    const [loading, setLoading] = useState(true);
    const [pages, setPages] = useState();
    const history = useHistory();
    useEffect(() => {
        let access_token = 'Bearer ' + ReactSession.get("access_token");
        axios({
            method: "get",
            url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/list_creative?paginate=true",
            headers: {
                Authorization: access_token
            }
        }).then((response) => {
            console.log("creative res", response);
            setCreative(response.data.content);
            setPages(Math.ceil(response.data.total / 10));
            setLoading(false);
        }).catch((err) => {
            console.log(err);
        })
    }, [])

    const handlePageClick = (data) => {
        console.log("data", data);
        let currentPage = data.selected + 1;
        let access_token = 'Bearer ' + ReactSession.get("access_token");
        axios.get(global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + `/list_creative?paginate=true&page=${currentPage}&ver=0&lang=hi`,
            { headers: { Authorization: access_token } })
            .then((response) => {
                console.log(response.data.content);
                setCreative(response.data.content);
            })
            .catch((err) => {
                console.error(String(err));
            });
    }

    const handleAdd = (e) => {
        history.push({
            pathname: "/add_creative",
            state: {
                t: "add",
                creative: {}
            }
        });
    }

    return (
        <div class="container">
            {loading ?<Loader />:
                <>
                    <div class="row">
                        <h2 style={{ "display": "inline-block" }} className="mb-2">Forms</h2>
                        <button className="btn btn-success pull-right mt-5" name="add" onClick={handleAdd}>ADD</button>
                    </div>
                    <div class="row">
                        <table className="table mb-0">
                            <thead className="bg-light">
                                <tr>
                                    <th scope="col" className="border-0">
                                        Id
                                    </th>
                                    <th scope="col" className="border-0">
                                        Name
                                    </th>
                                    <th scope="col" className="border-0">
                                        Type
                                    </th>
                                    <th scope="col" className="border-0">
                                        Created At
                                    </th>
                                    <th scope="col" className="border-0">
                                        Updated At
                                    </th>
                                    <th scope="col" className="border-0">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <CreativeTable creative={creative} />
                            </tbody>
                        </table>
                        <div>
                            <ReactPaginate
                                previousLabel={"previous"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                pageCount={pages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={(data) => {
                                    handlePageClick(data)
                                }}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>

                    </div></>}

        </div>
    )
}

export default Creative
