import axios from "axios";
import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactSession } from "react-client-session";
import FormComponent from "./component_forms";
import { APP_CONSTANTS } from '../../utils/constants';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Toast from '../Toast';
import Loader from "../Loader";

class LandingPageEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_id: "",
      ctype: "",
      logged_in: false,
      username: "",
      show_form: false,
      page_data: [],
      page: {},
      renderComponent: true,
      loading: true,
      showToast: false,
      toastMsg: "",
    };

    if (typeof ReactSession.get("username") !== "undefined") {
      this.state.logged_in = true;
      this.state.username = ReactSession.get("username");
    }
  }
  handleRender = () => {
    this.setState({
      renderComponent: false,
    });
  };

  componentDidMount() {
    this.getLandingPage();
  }

  getLandingPage() {
    const pathname = window.location.pathname;
    const arr = pathname.split("/");
    const page_id = arr[arr.length - 1];
    this.setState({
      page_id: page_id,
    });
    var access_token = "Bearer " + ReactSession.get("access_token");
    axios({
      method: "get",
      url:
        global.config.apiGateway.URL +
        APP_CONSTANTS.ad_sales +
        "/get_landing_page/" +
        page_id,
      headers: {
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(response.data.content.data.page_data);
        this.setState({
          page: response.data.content,
          page_data: response.data.content.data.page_data,
          loading: false,
        });
        console.log("page", this.state.page);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      ctype: e.target.value,
    });
  };

  handleOnDragEnd = (result) => {
    this.setState({ loading: true });
    if (!result.destination) return;
    console.log(this);
    const items = Array.from(this.state.page_data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    console.log(items);
    for (let index = 0; index < items.length; index++) {
      items[index]["position"] = index + 1;
    }

    // updateCharacters(items);
    this.setState({
      page_data: items,
    });

    var access_token = "Bearer " + ReactSession.get("access_token");
    console.log("items", items);
    axios({
      method: "post",
      url:
        global.config.apiGateway.URL +
        APP_CONSTANTS.ad_sales +
        "/reorder_page_component/" +
        this.state.page_id,
      data: items,
      headers: {
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(response);
        this.setState({ loading: false });
        if (response.status === 200) {
          this.setState({
            showToast: true,
            toastMsg: response.data.content,
          });

          setTimeout(() => {
            this.setState({
              showToast: false,
              toastMsg: "",
            });
          }, 4000);
        }
        this.setState({
          renderComponent: true,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(String(err));
        alert(err.response.data.content);
      });
  };

  handleDeleteComponent = (id) => {
    const pathname = window.location.pathname;
    const arr = pathname.split("/");
    const page_id = arr[arr.length - 1];
    let access_token = "Bearer " + ReactSession.get("access_token");
    axios({
      method: "get",
      url:
        global.config.apiGateway.URL +
        APP_CONSTANTS.ad_sales +
        "/delete_page_component/" +
        `${page_id}/${id}`,
      headers: {
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(response);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    if (!this.state.logged_in) {
      return (
        <div>
          <h5>Not logged in !</h5>
        </div>
      );
    }

    // return (<div></div>)
    return (
      <>
        {this.state.showToast ? <Toast msg={this.state.toastMsg} /> : <></>}
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className="col-md-12" style={{ "margin-bottom": "30px" }}>
            <div className="col-md-4 row">
              <div className="col-md-12">
                <h3>Add Display Component to Landing Page</h3>
                <small>Component are displayed as per the position</small>
              </div>
            </div>
            <form
              id="create_component"
              className="form-horizontal col-md-8"
              onSubmit={this.handleSubmit}
            >
              <div className="form-group">
                <label className="control-label col-md-3" htmlFor="type">
                  Component Type:
                </label>
                <div className="col-md-9">
                  <select
                    required
                    type="text"
                    className="form-control"
                    id="type"
                    name="type"
                    onChange={this.handleChange}
                    value={this.state.type}
                  >
                    <option value="" selected disabled hidden>
                      Select Component Type
                    </option>
                    {this.state.page.page_type === "default" && (
                      <option value="carousel">Carousel</option>
                    )}
                    {this.state.page.page_type === "default" && (
                      <option value="heading">Heading</option>
                    )}
                    {this.state.page.page_type === "default" && (
                      <option value="sub_text">Sub text</option>
                    )}
                    {this.state.page.page_type === "default" && (
                      <option value="normal_text">Normal text</option>
                    )}
                    <option value="static_image">Static Image</option>
                    {this.state.page.page_type === "default" && (
                      <option value="button">Button</option>
                    )}
                    {this.state.page.page_type === "default" && (
                      <option value="icons_with_text">Feature box</option>
                    )}
                  </select>
                </div>
              </div>
            </form>

            {this.state.ctype && (
              <FormComponent
                ctype={this.state.ctype}
                pageId={this.state.page_id}
              />
            )}
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Id
                  </th>
                  <th scope="col" className="border-0">
                    Page Id
                  </th>
                  <th scope="col" className="border-0">
                    Page Type
                  </th>
                  <th scope="col" className="border-0">
                    lang
                  </th>
                  <th scope="col" className="border-0">
                    Actions
                  </th>
                  <th scope="col" className="border-0">
                    Test AutoFill
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.page.landing_page_id}</td>
                  <td>{this.state.page.pid}</td>
                  <td>{this.state.page.page_type + " (" + this.state.page.data.client_id + ")" }</td>
                  <td>{this.state.page.lang}</td>
                  <td>
                    <button
                      onClick={() =>
                        window.open(
                          `${global.config.apiGateway.page_url}?pid=${this.state.page.pid}&lang=${this.state.page.lang}`,
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                    >
                      View <i class="fa fa-eye" aria-hidden="true"></i>{" "}
                    </button>
                  </td>
                  <td>
                    {/* {`${global.config.apiGateway.page_url}?pid=${this.state.page.pid}&lang=${this.state.page.lang}&name=anuj&number=+919999999999`} */}
                    <button
                      onClick={() =>
                        window.open(
                          `${global.config.apiGateway.page_url}?pid=${this.state.page.pid}&lang=${this.state.page.lang}&name=anuj&number=+919999999999`,
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                    >
                      Test AutoFill <i class="fa fa-eye" aria-hidden="true"></i>{" "}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            {this.state.page.page_type === "signup" && (
              <div>
                <br />
                <small>
                  Please provide a single background image, with main content on
                  the upper side. As the ~40% of bottom side is preoccupied with
                  Login Layer. The image ideally should be portrait with 16:9
                  ratio.
                </small>
              </div>
            )}
            <div class="panel panel-default" style={{ marginTop: "17vh" }}>
              <div class="panel panel-heading">Page Component display here</div>
              <div class="panel-body">
                {console.log("this", this)}
                <DragDropContext onDragEnd={this.handleOnDragEnd}>
                  <Droppable disabled droppableId="characters">
                    {(provided) => (
                      <div
                        className="characters"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        class="panel-body"
                      >
                        {this.state.page_data.map((component, idx) => {
                          console.log(component, idx);
                          return (
                            <Draggable
                              disabled
                              key={component.cid}
                              draggableId={component.cid}
                              index={idx}
                            >
                              {(provided) => (
                                <div
                                  disabled
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  class="panel panel-default"
                                >
                                  <div class="panel panel-heading">
                                    <span>
                                      <b>Component ID </b> {component.cid}{" "}
                                    </span>
                                    <span>
                                      <b>Position </b> {idx + 1}
                                    </span>

                                    {!this.state.page_data.is_active && (
                                      <button
                                        type="button"
                                        class="float-right btn btn-sm btn-primary"
                                        onClick={() =>
                                          this.handleDeleteComponent(
                                            component.logical_id
                                          )
                                        }
                                      >
                                        <i class="fas fa-trash"> Delete</i>
                                      </button>
                                    )}
                                  </div>
                                  <div class="panel-body box-border ">
                                    {console.log(component.ctype)}
                                    <FormComponent
                                      ctype={component.ctype}
                                      data={component}
                                      pageId={this.state.page_id}
                                      render={this.state.renderComponent}
                                      onRender={this.handleRender}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
            {this.state.page.page_type === "signup" && (
              <div>
                <h1 style={{ textAlign: "center", fontSize: "20px" }}>Preview</h1>
                <div className="col-md-12">
                  <div className="col-md-3"></div>
                  <div className="col-md-6">
                    {this.state.page &&
                      this.state.page.data &&
                      this.state.page.data.page_data[0] &&
                      this.state.page.data.page_data[0].cdata && (
                        <div
                          style={{
                            position: "relative",
                            width: "600px",
                            height: "1066px",
                            overflow: "hidden",
                            margin: "auto"
                          }}
                        >
                          <img
                            src={this.state.page.data.page_data[0].cdata}
                            alt="bg img"
                            style={{
                              position: "absolute",
                              zIndex: "-1",
                              width: "100%",
                              height: "100%",
                              objectFit: "fill",
                            }}
                          />
                          <div
                            style={{
                              backgroundColor: "#36413e88",
                              width: "100%",
                              height: "40%",
                              position: "absolute",
                              top: "calc(100% - 40%)",
                            }}
                          >
                            <this.LoginLayerPreview/>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
  LoginLayerPreview = () => {
    return (
      <div title="Not actual preview" style={{ marginTop: "16px" }}>
        <form
          className="form-group"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            color: "white",
            padding: "10px 30px",
          }}
        >
          <label htmlFor="" style={{ fontSize: "12px" }}>
            नाम
          </label>
          <input
            type="text"
            value={"आपका नाम"}
            style={{
              width: "100%",
              margin: "auto",
              marginBottom: "16px",
              border: "3px solid white",
              borderRadius: "8px",
              padding: "8px 24px",
              background: "transparent",
              color: "white",
            }}
          />
          <label htmlFor="" style={{ fontSize: "12px" }}>
            फ़ोन नंबर
          </label>
          <input
            type="text"
            value="1234567890"
            style={{
              width: "100%",
              margin: "auto",
              marginBottom: "16px",
              border: "3px solid white",
              borderRadius: "8px",
              padding: "8px 24px",
              background: "transparent",
              color: "white",
            }}
          />
          <button style={{textAlign: "center", padding: "10px", width: "100%", background: "orange", marginTop: "20px"}}>लॉगिन करे</button>
          <small>*Form structure/design may vary</small>
        </form>
      </div>
    );
  }
}

export default LandingPageEdit;
