import React, { Component } from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";
import { APP_CONSTANTS } from "../../utils/constants";
import CreateLandingPageFormFields from "./form_fields/create_page_form_fields";
import CreateLandingPageForm from './create_page_form';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Toast from '../Toast';
import Loader from "../Loader";

class PageFormFieldDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_id: props.match.params.form_id,
      form: {},
      fields: [],
      logged_in: false,
      username: "",
      show_form: false,
      show_formMeta: false,
      total_fields: 0,
      loading: true,
      showToast: false,
      toastMsg: "",
    };
    // console.log(this.props.match.params.form_id);
    if (typeof ReactSession.get("username") !== "undefined") {
      this.state.logged_in = true;
      this.state.username = ReactSession.get("username");
    }

    this.hide_or_show_form = this.hide_or_show_form.bind(this);
    this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
  }

  componentDidMount() {
    if (this.state.logged_in) this.get_form();
  }

  get_form = () => {
    var access_token = "Bearer " + ReactSession.get("access_token");
    // var data = [];
    axios
      .get(
        global.config.apiGateway.URL +
        APP_CONSTANTS.ad_sales +
        "/get_form_structure/" +
        this.state.form_id,
        {
          method: "get",
          headers: { Authorization: access_token },
        }
      )
      .then((response) => {
        const form = response.data.content;
        this.setState({
          form: form,
          fields: form.fields,
          total_fields: form.fields.length,
        });
        this.setState({ loading: false })
        console.log("field", response.data);
      })
      .catch((err) => {
        console.error(String(err));
        this.setState({ loading: false })
      });
  };

  nextPath(path) {
    this.props.history.push(path);
  }

  hide_or_show_form = () => {
    this.setState({ show_form: !this.state.show_form });
  };

  renderFormDetails = (form) => (
    <tr>
      <td>{form.id}</td>
      <td>{form.form_name}</td>
      <td>{form.display_title}</td>
      <td>{form.page_id}</td>
      <td>{form.is_active}</td>
      <td>{form.created_at}</td>
      <td>{form.updated_at}</td>
      {/* <td>Actions</td> */}
    </tr>
  );

  handleOnDragEnd(result) {
    if (!result.destination) return;
    console.log(this.state);
    const items = Array.from(this.state.fields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    console.log(items);
    for (let index = 0; index < items.length; index++) {
      items[index]["position"] = index + 1;
    }

    // updateCharacters(items);
    this.setState({
      fields: items,
    });

    var access_token = "Bearer " + ReactSession.get("access_token");
    console.log("items", items);
    axios({
      method: "post",
      url:
        global.config.apiGateway.URL +
        APP_CONSTANTS.ad_sales +
        "/update_fields_order",
      data: items,
      headers: {
        Authorization: access_token,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          //alert(response.data.content);
          this.setState({
            showToast: true,
            toastMsg: response.data.content
          })

          setTimeout(() => {
            this.setState({
              showToast: false,
              toastMsg: ""
            })
          }, 4000)
        }
      })
      .catch((err) => {
        console.error(String(err));
        // if (err.response.status === 400) {
        alert(err.response.data.content);
        // alert(err.response.data.content.errorMsg);
        // }
      });
  }

  handleFormFieldDelete = (id) => {
    this.setState({ loading: true });
    var access_token = "Bearer " + ReactSession.get("access_token");
    console.log(global.config.apiGateway.URL +
      APP_CONSTANTS.ad_sales + "/delete_form_field/" + id);
    axios.get(
      global.config.apiGateway.URL +
      APP_CONSTANTS.ad_sales + "/delete_form_field/" + id,
      {
        method: "get",
        headers: { Authorization: access_token },
      }
    ).then((response) => {
      console.log("field deleted", response);
      this.setState({ loading: false });
      window.location.reload();
    }).catch((err) => {
      alert(err.response.data.content);
    })
  }

  handleChange = (e) => {
    let name = e.target.name;
    let id = e.target.getAttribute('fieldid');
    let newfields = [...this.state.fields];

    if (name == "is_required") {
      for (let i = 0; i < newfields.length; i++) {
        let field = newfields[i];
        if (field.id == id) {
          if (e.target.defaultChecked) {
            field[name] = false;
          } else {
            field[name] = true;
          }
        }
      }
    } else {
      let value = e.target.value;
      newfields.forEach((field) => {
        if (field.id == id) {
          field[name] = value;
        }
      })

    }
    this.setState({
      fields: newfields
    })
  }

  handleUpdate = (e) => {
    let id = e.target.id;
    let obj = this.state.fields.find((field) => {
      return field.id == id;
    })
    console.log(obj);
    this.setState({ loading: true });
    let formdata = new FormData();
    let cId = obj.cid || ""
    cId = cId.replaceAll(" ", "_")
    cId = cId.toLowerCase();
    formdata.append("question_text", obj.question_text);
    formdata.append("type", obj.type);
    formdata.append("placeholder_text", obj.placeholder_text);
    formdata.append("is_required", obj.is_required);
    formdata.append("cid", cId);
    var access_token = 'Bearer ' + ReactSession.get("access_token");
    axios({
      method: "post",
      url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/edit_form_field/" + id,
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: access_token
      },
    }).then((response) => {
      console.log(response);
      this.setState({ loading: false });
      window.location.reload();
    }).catch((err) => {
      this.setState({ loading: false });
      console.log(err);
    })

  }

  render() {
    if (!this.state.logged_in) {
      return (
        <div>
          <h5>Not logged in !</h5>
        </div>
      );
    }
    return (
      <>
        {this.state.showToast ? <Toast msg={this.state.toastMsg} /> : <></>}
        {this.state.loading ? <Loader /> :
          <div class="container">
            <div class="row">
              <h2 style={{ display: "inline-block" }} className="my-8">
                <b>Form Detail:</b>
                {this.state.form.form_name}
              </h2>
            </div>
            <div class="row">
              <button className="btn btn-success pull-right mt-5" onClick={() => this.setState({ show_formMeta: !this.state.show_formMeta })} type="submit">edit Form</button>
            </div>
            {this.state.show_formMeta && <CreateLandingPageForm formMeta={this.state.form} />}
            <div class="row my-16">
              <table className="table table-bordered mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Form Id
                    </th>
                    <th scope="col" className="border-0">
                      Form Name
                    </th>
                    <th scope="col" className="border-0">
                      Display Title
                    </th>
                    <th scope="col" className="border-0">
                      Page Id
                    </th>
                    <th scope="col" className="border-0">
                      Is Active
                    </th>
                    <th scope="col" className="border-0">
                      Created At
                    </th>
                    <th scope="col" className="border-0">
                      Updated At
                    </th>
                    {/* <th scope="col" className="border-0">
                                       Actions
                                	</th> */}
                  </tr>
                </thead>
                <tbody>{this.renderFormDetails(this.state.form)}</tbody>
              </table>
              <CreateLandingPageFormFields
                data={{
                  position: this.state.total_fields + 1,
                  form_id: this.state.form_id,
                }}
              />
            </div>
            {this.state.fields.length > 0 && (
              <div class="panel panel-default" style={{ marginTop: "5vh" }}>
                <div class="panel-heading">
                  <div className="d-inline">
                    <p>Form Fields</p>
                  </div>
                </div>
                <div class="panel-body">
                  <DragDropContext onDragEnd={this.handleOnDragEnd}>
                    <Droppable disabled droppableId="characters">
                      {(provided) => (
                        <div
                          className="characters"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          class="panel-body"
                        >
                          {/* {this.renderFields(this.state.fields)} */}

                          {this.state.fields.map((field, idx) => {
                            return (
                              <Draggable
                                disabled
                                key={field.id}
                                draggableId={String(field.id)}
                                index={idx}
                              >
                                {(provided) => (
                                  <div
                                    disabled
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    class="panel panel-default"
                                  >
                                    <div class="panel panel-heading">
                                      <span>
                                        <b>Field ID </b> {field.id}{" "}
                                      </span>
                                      <span>
                                        <b>Position </b> {field.position}
                                      </span>

                                      {/* add onclick implememtation */}
                                      {!this.state.form.is_active && (
                                        <button
                                          type="button"
                                          class="float-right btn btn-sm btn-primary"
                                          onClick={() => this.handleFormFieldDelete(field.id)}
                                        >
                                          <i class="fas fa-trash"> Delete</i>
                                        </button>
                                      )}
                                      {!this.state.form.is_active && (
                                        <button
                                          type="button"
                                          class="float-right btn btn-sm btn-primary mx-2"
                                          id={field.id}
                                          onClick={this.handleUpdate}
                                        >
                                          Update
                                        </button>
                                      )}
                                    </div>
                                    <div class="panel-body box-border ">
                                      <form
                                        id={`form-${field.id}`}
                                        className="form-horizontal col-md-8"
                                        onSubmit={this.handleSubmit}
                                      >
                                        <div className="form-group">
                                          <label
                                            className="control-label col-md-3"
                                            htmlFor="type"
                                          >
                                            Field Type*
                                          </label>
                                          <div className="col-md-9">
                                            <select
                                              disabled={this.state.form.is_active}
                                              required
                                              type="text"
                                              className="form-control"
                                              id="type"
                                              fieldid={field.id}
                                              name="type"
                                              onChange={this.handleChange}
                                              value={field.type}
                                            >
                                              <option
                                                value=""
                                                selected
                                                disabled
                                                hidden
                                              >
                                                Select Field Type
                                              </option>
                                              <option
                                                selected={field.type === "number"}
                                                value="number"
                                              >
                                                Number
                                              </option>
                                              <option
                                                selected={field.type === "phone_no"}
                                                value="phone_no"
                                              >
                                                Phone number
                                              </option>
                                              <option
                                                selected={field.type === "text"}
                                                value="text"
                                              >
                                                Text
                                              </option>
                                              <option
                                                selected={field.type === "select"}
                                                value="select"
                                              >
                                                Select
                                              </option>
                                              <option
                                                selected={field.type === "name"}
                                                value="name"
                                              >
                                                Name
                                              </option>


                                            </select>
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <label
                                            className="control-label col-md-3"
                                            htmlFor="question"
                                          >
                                            Question Text*
                                          </label>
                                          <div className="col-md-9">
                                            <input
                                              disabled={this.state.form.is_active}
                                              required
                                              type="text"
                                              className="form-control"
                                              id="question_text"
                                              fieldid={field.id}
                                              placeholder="Enter Question Text"
                                              name="question_text"
                                              onChange={this.handleChange}
                                              value={field.question_text}
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <label
                                            className="control-label col-md-3"
                                            htmlFor="placeholder_text"
                                          >
                                            Placeholder Text*
                                          </label>
                                          <div className="col-md-9">
                                            <input
                                              disabled={this.state.form.is_active}
                                              required
                                              type="text"
                                              className="form-control"
                                              id="placeholder_text"
                                              fieldid={field.id}
                                              placeholder="Enter Placeholder Text"
                                              name="placeholder_text"
                                              onChange={this.handleChange}
                                              value={field.placeholder_text}
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <label
                                            className="control-label col-md-3"
                                            htmlFor="text"
                                          >
                                            CId:
                                          </label>
                                          <div className="col-md-9">
                                            <input
                                              disabled={this.state.form.is_active}
                                              required
                                              type="text"
                                              className="form-control"
                                              id="cid"
                                              fieldid={field.id}
                                              placeholder="Enter component id"
                                              name="cid"
                                              onChange={this.handleChange}
                                              value={field.cid}
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <label
                                            className="control-label col-md-3"
                                            htmlFor="is_required"
                                          >
                                            Is Required(Mandatory)
                                          </label>
                                          <div className="col-md-9">
                                            {console.log("required", field.is_required)}
                                            <input
                                              disabled={this.state.form.is_active}
                                              required
                                              type="checkbox"
                                              id="is_required"
                                              fieldid={field.id}
                                              placeholder="Is Required"
                                              name="is_required"
                                              onChange={this.handleChange}
                                              defaultChecked={field.is_required}
                                            />
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                    <div class="panel-footer">
                                      {field.type === "select" && (
                                        <div>
                                          <Link
                                            to={
                                              "/edit_form/show_options/" +
                                              field.id
                                            }
                                          >
                                            <button
                                              type="button"
                                              class="btn btn-sm btn-primary"
                                            // onClick={() =>
                                            //   this.nextPath(
                                            //     "/edit_form/show_options/:form_id"
                                            //   )
                                            // }
                                            >
                                              Show Options
                                            </button>
                                          </Link>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            )}
          </div>}
      </>
    );
  }
}

export default PageFormFieldDetails;

