import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import { APP_CONSTANTS } from '../../utils/constants';
import { Link, useHistory } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import Loader from '../Loader';
import { formatDateTimeAM } from 'utils/datetime';

function InventoryTable(props) {
    const history = useHistory();
    const handleUpdate = (e) => {
        console.log(e.target.getAttribute("k"));
        let k = e.target.getAttribute("k");
        let p = e.target.getAttribute("i");
        let id = e.target.getAttribute("id");
        history.push({
            pathname: "/add_inventory",
            state: {
                type: "update",
                key: k,
                pos: p,
                id: id
            }
        })
    }
    return (
        <>
            {props.inventories.map((item, idx) => {
                return (
                    <tr>
                        <td>{item.id}</td>
                        <td>{item.key}</td>
                        <td>{item.position}</td>
                        <td>{formatDateTimeAM(item.created_at)}</td>
                        <td>{formatDateTimeAM(item.updated_at)}</td>
                        <td k={item.key} i={item.position} id={item.id} onClick={(e) => handleUpdate(e)}>
                            Update
                        </td>
                    </tr>
                )
            })

            }
        </>
    )

}

function Inventory() {
    const [inventories, setInventories] = useState([]);
    const [loggedin, setloggedin] = useState(true);
    const [pages, setPages] = useState();
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        if (typeof ReactSession.get("username") !== "undefined") {
            setloggedin(true);
        }
    })

    useEffect(() => {
        if (loggedin) {
            let access_token = 'Bearer ' + ReactSession.get("access_token");
            axios({
                method: "get",
                url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/list_inventory?paginate=true",
                headers: {
                    Authorization: access_token
                }
            }).then((response) => {
                console.log(response);
                setInventories(response.data.content);
                setPages(Math.ceil(response.data.total / 10));
                setLoading(false)
            }).catch((err) => {
                console.log(err);
                setLoading(false);
            })
        }
    }, [])

    const handlePageClick = (data) => {
        console.log("data", data);
        let currentPage = data.selected;
        let access_token = 'Bearer ' + ReactSession.get("access_token");
        axios.get(global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + `/list_inventory?paginate=true&page=${currentPage + 1}&ver=0&lang=hi`,
            { headers: { Authorization: access_token } })
            .then((response) => {
                console.log(response.data.content);
                setInventories(response.data.content);
            })
            .catch((err) => {
                console.error(String(err));
            });
    }

    const handleAdd = (e) => {
        history.push({
            pathname: "/add_inventory",
            state: {
                type: "add",
                key: "",
                pos: "",
                id: ""
            }
        });
    }

    return (
        <div class="container">
            {loading ? <Loader />:
            <>
                <div class="row">
                    <h2 style={{ "display": "inline-block" }} className="mb-2">Forms</h2>
                    <button className="btn btn-success pull-right mt-5" name="add" onClick={handleAdd}>ADD</button>
                </div>
                <div class="row">
                    <table className="table mb-0">
                        <thead className="bg-light">
                            <tr>
                                <th scope="col" className="border-0">
                                    Id
                                </th>
                                <th scope="col" className="border-0">
                                    Key
                                </th>
                                <th scope="col" className="border-0">
                                    Position
                                </th>
                                <th scope="col" className="border-0">
                                    Created At
                                </th>
                                <th scope="col" className="border-0">
                                    Updated At
                                </th>
                                <th scope="col" className="border-0">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <InventoryTable inventories={inventories} />
                        </tbody>
                    </table>
                    <div>
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={pages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />
                    </div>

                </div>
            </>}

        </div>
    )
}

export default Inventory
