import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import { APP_CONSTANTS } from '../../utils/constants';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select'
import Loader from '../Loader';

function AddClientAd() {
    const [clients, setClients] = useState();
    const [clientId, setClientId] = useState("");
    const [inventory, setInventory] = useState();
    const [inventoryId, setInventoryId] = useState("");
    const [creative, setCreative] = useState();
    const [creativeId, setCreativeId] = useState("");
    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [selectStates, setSelectStates] = useState([]);
    const [selectDistricts, setSelectDistricts] = useState([]);
    const [subDistricts, setSubDistricts] = useState([]);
    const [selectSubDistricts, setSelectSubDistricts] = useState([]);
    const [villages, setVillages] = useState([]);
    const [selectVillages, setSelectVillages] = useState([]);
    const [adname, setadname] = useState("");
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    var access_token = "Bearer " + ReactSession.get("access_token");
    useEffect(() => {
        axios.get(
            global.config.apiGateway.URL +
            APP_CONSTANTS.ad_sales + "/list_all_clients",
            {
                method: "get",
                headers: { Authorization: access_token },
            }
        ).then((response) => {
            console.log("client", response.data.content);
            setClients(response.data.content);
        }).catch((err) => {
            console.log("error", err);
        })

        axios.get(
            global.config.apiGateway.URL +
            APP_CONSTANTS.ad_sales + "/list_inventory",
            {
                method: "get",
                headers: { Authorization: access_token },
            }
        ).then((response) => {
            console.log("inventory", response.data.content);
            setInventory(response.data.content);
        }).catch((err) => {
            console.log("error", err);
        })

        axios.get(
            global.config.apiGateway.URL +
            APP_CONSTANTS.ad_sales + "/list_creative",
            {
                method: "get",
                headers: { Authorization: access_token },
            }
        ).then((response) => {
            console.log(" creative", response.data.content);
            setCreative(response.data.content);
        }).catch((err) => {
            console.log("error", err);
        })

    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let statedata = [];
        let districtdata = [];
        let subdistrictdata = [];
        let villagedata = [];
        selectStates.forEach((state) => {
            statedata.push(String(state.value));
        })
        selectDistricts.forEach((district) => {
            districtdata.push(String(district.value));
        })
        selectSubDistricts.forEach((subdistrict) => {
            subdistrictdata.push(String(subdistrict.value));
        })
        selectVillages.forEach((village) => {
            villagedata.push(String(village.value));
        })

        let data = {
            ad_name: adname,
            client_id: clientId,
            inventory_id: inventoryId,
            creative_id: creativeId,
            states: statedata,
            districts: districtdata,
            subdivisions: subdistrictdata,
            villages: villagedata
        }

        console.log(data);

        axios({
            method: "post",
            url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/add_client_ad",
            data: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: access_token
            }
        }).then((response) => {
            console.log(response);
            setLoading(false);
            history.push("/clients_ads")
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        })
    }

    useEffect(() => {
        getStates();
    }, [])

    useEffect(() => {
        let arr = [];
        if (selectStates.length == 0) {
            setDistricts([]);
            setSelectDistricts([]);
            setSubDistricts([]);
            setSelectSubDistricts([]);
            setVillages([]);
            setSelectVillages([]);

        } else {
            selectStates.forEach((state) => {
                getDistricts(state.value, arr);
            })
        }

    }, [selectStates])

    useEffect(() => {
        let arr = [];
        if (selectDistricts.length == 0) {
            setSubDistricts([]);
            setSelectSubDistricts([]);
            setVillages([]);
            setSelectVillages([]);
        } else {
            selectDistricts.forEach((district) => {
                getSubDistricts(district.value, arr);
            })
        }
    }, [selectDistricts])

    useEffect(() => {
        let arr = [];
        if (selectSubDistricts.length == 0) {
            setVillages([]);
            setSelectVillages([]);
        } else {
            selectSubDistricts.forEach((subDistrict) => {
                getVillages(subDistrict.value, arr);
            })
        }

    }, [selectSubDistricts])

    const getStates = () => {
        axios.get(
            global.config.apiGateway.URL + '/api/states?lang=en',
            {
                headers: { Authorization: access_token },
            }
        ).then((response) => {
            console.log("state", response.data.locations);
            let data = response.data.locations;
            let arr = [];
            for (let i = 0; i < data.length; i++) {
                let obj = { value: data[i].id, label: data[i].name };
                arr.push(obj);
            }
            setStates(arr);
        }).catch((err) => {
            console.log("error", err);
        })
    }

    const getDistricts = (stateId, arr) => {
        axios.get(
            global.config.apiGateway.URL + `/api/state/${stateId}/districts?lang=en`,
            {
                headers: { Authorization: access_token },
            }
        ).then((response) => {
            console.log("district", response.data.locations);
            let data = response.data.locations;
            for (let i = 0; i < data.length; i++) {
                let obj = { value: data[i].id, label: data[i].name };
                arr.push(obj);
            }
            let newArr = [...arr];
            setDistricts(newArr);
        }).catch((err) => {
            console.log("error", err);
        })
    }

    const getSubDistricts = (districtID, arr) => {
        axios.get(
            global.config.apiGateway.URL + `/api/district/${districtID}/sub_divisions?lang=en`,
            {
                headers: { Authorization: access_token },
            }
        ).then((response) => {
            console.log("sd", response.data.locations);
            let data = response.data.locations;
            for (let i = 0; i < data.length; i++) {
                let obj = { value: data[i].id, label: data[i].name };
                arr.push(obj);
            }
            let newArr = [...arr];
            setSubDistricts(newArr);
        }).catch((err) => {
            console.log("error", err);
        })
    }

    const getVillages = (subDistrictID, arr) => {
        console.log("vid", subDistrictID)
        axios.get(
            global.config.apiGateway.URL + `/api/sub_division/${subDistrictID}/villages?lang=en`,
            {
                headers: { Authorization: access_token },
            }
        ).then((response) => {
            console.log("village", response.data.locations);
            let data = response.data.locations;
            for (let i = 0; i < data.length; i++) {
                let obj = { value: data[i].id, label: data[i].name };
                arr.push(obj);
            }
            let newArr = [...arr];
            setVillages(newArr);
        }).catch((err) => {
            console.log("error", err);
        })
    }

    const handleState = (e) => {
        setSelectStates(e);
    }

    const handleDistricts = (e) => {
        setSelectDistricts(e);
    }

    const handleSubDistricts = (e) => {
        setSelectSubDistricts(e);
    }

    const handleVillages = (e) => {
        setSelectVillages(e);
    }

    const handleClients = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let clientId = optionElement.getAttribute('id');
        setClientId(clientId);
    }

    const handleInventory = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let inventoryId = optionElement.getAttribute('id');
        setInventoryId(inventoryId);
    }
    const handleCreative = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let creativeId = optionElement.getAttribute('id');
        setCreativeId(creativeId);
    }

    return (
        <div className="container">
            {loading ? <Loader /> :
                <form className="form-horizontal col-md-8" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label className="control-label col-md-3">Ad Name: </label>
                        <div className="col-md-9">
                            <input required type="text" className="form-control" id="ad_name" placeholder="Enter Ad name" onChange={(e) => setadname(e.target.value)} value={adname} /><br />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-3">Client ID: </label>
                        <div className="col-md-9">
                            <select required className="form-control" onChange={(e) => handleClients(e)}>
                                <option>Select</option>
                                {clients?.map((client) => {
                                    return (<option id={client.id} value={client.client_name}>{client.client_name} </option>)
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="control-label col-md-3">Inventory ID: </label>
                        <div className="col-md-9">
                            <select required className="form-control" onChange={(e) => handleInventory(e)}>
                                <option>Select</option>
                                {inventory?.map((item) => {
                                    return (<option id={item.id} value={item.key}>{item.key} </option>)
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="control-label col-md-3">Creative ID: </label>
                        <div className="col-md-9">
                            <select required className="form-control" onChange={(e) => handleCreative(e)}>
                                <option>Select</option>
                                {creative?.map((item) => {
                                    return (<option id={item.id} value={item.name}>{item.name} </option>)
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-3">States: </label>
                        <div className="col-md-9">
                            <Select required value={selectStates} options={states} isMulti onChange={(e) => handleState(e)} />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="control-label col-md-3">Districts: </label>
                        <div className="col-md-9">
                            <Select required value={selectDistricts} options={districts} isMulti onChange={(e) => handleDistricts(e)} />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="control-label col-md-3">Sub Districts: </label>
                        <div className="col-md-9">
                            <Select required value={selectSubDistricts} options={subDistricts} isMulti onChange={(e) => handleSubDistricts(e)} />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="control-label col-md-3">Villages: </label>
                        <div className="col-md-9">
                            <Select required value={selectVillages} options={villages} isMulti onChange={(e) => handleVillages(e)} />
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="col-md-offset-2 col-md-10">
                            <input type="submit" className="btn btn-default" style={{ float: "right" }} name="submit" value="Submit"></input>
                        </div>
                    </div>

                </form>}
        </div>
    )
}

export default AddClientAd
