import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Loader() {
    return (
        <Box sx={{ display: 'flex' }} >
            <CircularProgress size="100px" style={{ marginTop: "17%", marginLeft: "45%" }} />
        </Box>
    )
}

export default Loader
