import React, { Component, useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import ReactPaginate from "react-paginate";
import { APP_CONSTANTS } from '../../utils/constants';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Loader from '../Loader';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Chip, IconButton, Snackbar } from '@mui/material';
import { copyToClipboard } from 'utils/misc';

function TableEntries(props) {
    const [open, setOpen] = useState(false);
    const [copiedText, setCopiedText] = useState('');
    let history = useHistory();
    var access_token = 'Bearer ' + ReactSession.get("access_token");
    const duplicatePage = (e) => {
        console.log(e.target.id);
        axios(
            {
                method: "get",
                url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/duplicate_page/" + e.target.id,
                headers: {
                    Authorization: access_token
                }
            }
        ).then((response) => {
            console.log(response);
            history.push(`/edit_page_component/${response.data.content.id}`)
        }).catch((err) => {
            console.log(err);
        })
    }
    
    const copyToClipboardHandler = useCallback((text) => {
        copyToClipboard(text)
        setCopiedText(text)
        setOpen(true)
    }, [])

    return (
        <>
                <Snackbar
                    open={open}
                    autoHideDuration={4000}
                    message={"Copied to clipboard - " + copiedText}
                />  
            <tr>
                <td>{props.data.id}</td>
                <td>{props.data.page_name}</td>
                <td>{props.data.page_id} 
                    <IconButton 
                        color="primary" 
                        aria-label="copy to clipboard" 
                        component="span" 
                        onClick={() => copyToClipboardHandler(props.data.page_id)}
                    >
                        <ContentCopyIcon />
                    </IconButton>
                </td>
                <td>
                    <button onClick={() => history.push("/edit_page_component/" + props.data.id)}>Edit</button><br />

                    <button onClick={() => window.open(`${global.config.apiGateway.page_url}?pid=${props.data.page_id}&lang=${props.data.lang}`, '_blank', 'noopener,noreferrer')}>
                        View </button><br />
                    <button id={props.data.id} onClick={duplicatePage}>Duplicate</button>
                    <br />
                    
                    {/* {`${global.config.apiGateway.page_url}?pid=${this.state.page.pid}&lang=${this.state.page.lang}&name=anuj&number=+919999999999`} */}
                        <button onClick={() => window.open(`${global.config.apiGateway.page_url}?pid=${props.data.page_id}&lang=${props.data.lang}&name=anuj&number=+919999999999`, '_blank', 'noopener,noreferrer')}>
                        Test AutoFill <i class="fa fa-eye" aria-hidden="true"></i> </button>
                </td>
            </tr>
        </>
    )
}

function AddPage(props) {
  console.log(props.langs);
  const [client_id, setClient] = useState("");
  const [lang_id, setLang] = useState("");
  const [advertiser_logo_url, setAdvertser] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [pageType, setPageType] = useState("default");
  const [isForLead, setIsForLead] = useState(true);
  const history = useHistory();

  const handleClients = (e) => {
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let clientId = optionElement.getAttribute("id");
    setClient(clientId);
  };

  const handleLangs = (e) => {
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let langId = optionElement.getAttribute("id");
    setLang(langId);
  };
  const handlePageType = (e) => {
    let value = e.target.value;
    setPageType(value);
  };

  const handleSubmit = (e) => {
    props.setLoading(true);
    var access_token = "Bearer " + ReactSession.get("access_token");
    var formdata = new FormData();
    if (!isForLead) {
      // dont consider this page a lead
      let NotAClientID = "7";
      alert("not a leead");
      setClient(NotAClientID);
      formdata.append("client_id", NotAClientID);
    } else {
      formdata.append("client_id", client_id);
    }
    formdata.append("is_lead", isForLead);
    formdata.append("advertiser_logo_url", advertiser_logo_url);
    formdata.append("name", name);
    formdata.append("description", description);
    formdata.append("lang_id", lang_id);
    formdata.append("page_type", pageType);
    const value = JSON.stringify(Object.fromEntries(formdata.entries()));
    axios({
      method: "post",
      url:
        global.config.apiGateway.URL +
        APP_CONSTANTS.ad_sales +
        "/add_landing_page/",
      data: value,
      headers: {
        "Content-Type": "application/json",
        Authorization: access_token,
      },
    })
      .then((response) => {
        props.setLoading(false);
        console.log(response);
        if (response.data.content.page_type === "signup") {
          alert(
            "please add a static image as first component on the next screen!"
          );
        }
        history.push(`/edit_page_component/${response.data.content.id}`);
      })
      .catch((err) => {
        props.setLoading(false);
        console.log(err);
      });
  };

  return (
    <div className="container">
      <div className="col-md-12" style={{ "margin-bottom": "30px" }}>
        <form id="create_field" className="form-horizontal col-md-8">
          <div className="form-group">
            <label className="control-label col-md-3" htmlFor="page_type">
              Page Type
            </label>
            <div className="col-md-9">
              <select
                required
                type="text"
                className="form-control"
                id="page_type"
                name="page_type"
                onChange={handlePageType}
              >
                <option value="default">Default</option>
                <option value="signup">Signup</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            {pageType === "signup" && (
              <div>
                <label className="control-label col-md-3" htmlFor="isForLead">
                  Is this a sales lead?
                </label>
                <div className="col-md-9">
                  <select
                    required
                    type="text"
                    className="form-control"
                    id="isForLead"
                    name="isForLead"
                    onChange={(e) => {
                      setIsForLead(e.value === "yes");
                    }}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>
            )}
          </div>
          {isForLead && (
            <div className="form-group">
              <label className="control-label col-md-3" htmlFor="type">
                Client*
              </label>
              <div className="col-md-9">
                <select
                  required
                  type="text"
                  className="form-control"
                  id="type"
                  name="client"
                  onChange={handleClients}
                >
                  <option>Choose Client</option>
                  {props.clients.map((client) => {
                    return (
                      <option id={client.id} value={client.client_name}>
                        {client.client_name}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          )}
          <div className="form-group">
            <label className="control-label col-md-3" htmlFor="question">
              Advertiser Logo URL*
            </label>
            <div className="col-md-9">
              <input
                required
                type="text"
                className="form-control"
                id="advertiser_logo_url"
                placeholder="Enter Advertiser Logo URL"
                name="advertiser_logo_url"
                onChange={(e) => setAdvertser(e.target.value)}
                value={advertiser_logo_url}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-3" htmlFor="text">
              Name*
            </label>
            <div className="col-md-9">
              <input
                required
                type="text"
                className="form-control"
                id="name"
                placeholder="Enter Page Name"
                name="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-md-3" htmlFor="text">
              Description*
            </label>
            <div className="col-md-9">
              <input
                required
                type="text"
                className="form-control"
                id="description"
                placeholder="Enter Description"
                name="description"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-3" htmlFor="type">
              Languages*
            </label>
            <div className="col-md-9">
              <select
                required
                type="text"
                className="form-control"
                id="lang"
                name="lang"
                onChange={handleLangs}
              >
                <option>Choose Language</option>
                {props.langs.map((lang) => {
                  return (
                    <option id={lang.id} value={lang.lang}>
                      {lang.lang}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="form-group">
            <div className="col-md-offset-2 col-md-10">
              <button
                type="button"
                className="btn btn-default"
                style={{ float: "right" }}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

let curPage;
function LandingPage(props) {
  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [clients, setClients] = useState([]);
  const [showpage, setshowpage] = useState(false);
  const [langs, setLangs] = useState([]);
  const [loading, setLoading] = useState(true);

  let access_token = "Bearer " + ReactSession.get("access_token");
  let limit = 10;

  useEffect(() => {
    axios({
      method: "get",
      url:
        global.config.apiGateway.URL +
        APP_CONSTANTS.ad_sales +
        "/list_landing_page/?page=1&ver=0&lang=hi",
      headers: {
        Authorization: access_token,
      },
    })
      .then((response) => {
        //console.log(response.data.content);
        setpageCount(Math.ceil(response.data.total / limit));
        setItems(response.data.content);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        global.config.apiGateway.URL +
          APP_CONSTANTS.ad_sales +
          "/list_languages/",
        {
          method: "get",
          headers: { Authorization: access_token },
        }
      )
      .then((response) => {
        console.log("langs", response.data.content);
        setLangs(response.data.content);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(
        global.config.apiGateway.URL +
          APP_CONSTANTS.ad_sales +
          "/list_all_clients",
        {
          method: "get",
          headers: { Authorization: access_token },
        }
      )
      .then((response) => {
        console.log("client", response.data.content);
        setClients(response.data.content);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  const fetchData = async (currentPage) => {
    var data = axios({
      method: "get",
      url:
        global.config.apiGateway.URL +
        APP_CONSTANTS.ad_sales +
        `/list_landing_page/?page=${currentPage}`,
      headers: {
        Authorization: access_token,
      },
    })
      .then((response) => {
        //console.log(response.data.content);
        // setpageCount(Math.ceil(response.data.total / limit));
        return response.data.content;
      })
      .catch((err) => {
        console.log(err);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    // console.log(data.selected);
    let currentPage = data.selected + 1;
    curPage = currentPage;
    const dataFromServer = await fetchData(currentPage);
    console.log(dataFromServer.length);
    console.log(dataFromServer[2]);
    setItems(dataFromServer);
  };

  return (
    <div class="container">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div class="row">
            <button
              className="btn btn-success pull-right mt-5"
              type="submit"
              onClick={() => setshowpage(!showpage)}
            >
              Add Page
            </button>
          </div>
          {showpage && (
            <AddPage clients={clients} langs={langs} setLoading={setLoading} />
          )}
          <div class="row">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    Id
                  </th>
                  <th scope="col" className="border-0">
                    Page Name
                  </th>
                  <th scope="col" className="border-0">
                    Page Id
                  </th>
                  <th scope="col" className="border-0">
                    Page Type
                  </th>
                  <th scope="col" className="border-0">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((page) => {
                  return <TableEntries data={page} />;
                })}
              </tbody>
            </table>
            <div>
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
            export default LandingPage