import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import { APP_CONSTANTS } from '../../utils/constants';
import Loader from '../Loader';

class AttachForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            page_id: "",
            clients: [],
            client_id: "",
            advertiser_logo_url: "",
            name: "",
            description: "",
            langs: [],
            lang_id: "",
            loading: false
        }
    }

    componentDidMount() {
        this.getClients()
        this.getLang();
    }

    getClients = () => {
        var access_token = "Bearer " + ReactSession.get("access_token");
        axios.get(
            global.config.apiGateway.URL +
            APP_CONSTANTS.ad_sales + "/list_all_clients",
            {
                method: "get",
                headers: { Authorization: access_token },
            }
        ).then((response) => {
            console.log("client", response.data.content);
            this.setState({
                clients: response.data.content
            });
        }).catch((err) => {
            console.log("error", err);
        })
    }

    getLang = () => {
        var access_token = "Bearer " + ReactSession.get("access_token");
        axios.get(
            global.config.apiGateway.URL +
            APP_CONSTANTS.ad_sales + "/list_languages/",
            {
                method: "get",
                headers: { Authorization: access_token },
            }
        ).then((response) => {
            console.log(response.data.content);
            this.setState({
                langs: response.data.content,
            })
        }).catch((err) => {
            console.log(err);
        })
    }

    handleChange = (e) => {
        e.preventDefault();
        let key = e.target.name;
        let value = e.target.value;

        this.setState({
            [key]: value
        })
    }

    handleSubmit = (e) => {
        this.setState({loading: true});
        const pathname = window.location.pathname
        const arr = pathname.split("/");
        const form_id = arr[arr.length - 1];
        let client_id = this.state.client_id;
        let advertiser_logo_url = this.state.advertiser_logo_url;
        let name = this.state.name;
        let description = this.state.description;
        let lang_id = this.state.lang_id;

        var access_token = 'Bearer ' + ReactSession.get("access_token");
        var formdata = new FormData();
        formdata.append("client_id", client_id);
        formdata.append('advertiser_logo_url', advertiser_logo_url);
        formdata.append('form_id', form_id);
        formdata.append('name', name);
        formdata.append('description', description);
        formdata.append('lang_id', lang_id);
        const value = JSON.stringify(Object.fromEntries(formdata.entries()));
        console.log(value);
        axios(
            {
                method: "post",
                url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/attach_landing_page/",
                data: value,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: access_token
                }
            }
        ).then((response) => {
            console.log(response);
            this.setState({loading: false});
            this.setState({
                redirect: true,
                page_id: response.data.content.id
            })

        }).catch((err) => {
            console.log(err);
            this.setState({loading: false});
        })
    }

    handleClients = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let clientId = optionElement.getAttribute('id');
        this.setState({
            client_id: clientId
        })
    }

    handleLangs = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let langId = optionElement.getAttribute('id');
        this.setState({
            lang_id: langId
        })
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to={`/edit_page_component/${this.state.page_id}`} />
        }
        return (
            <div>
                {this.state.loading ? <Loader /> : <></>}
                <form className="form-horizontal col-md-8">

                    <div className="form-group">
                        <label className="control-label col-md-3">Client ID {": "}</label>
                        <div className="col-md-9">
                            <select required className="form-control" onChange={this.handleClients}>
                                <option>Select</option>
                                {this.state.clients.map((client) => {
                                    return (<option id={client.id} value={client.client_name}>{client.client_name} </option>)
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-3">Advertiser Logo URL: </label>
                        <div className="col-md-9">
                            <input required type="text" className="form-control" id="advertiser_logo_url" name="advertiser_logo_url" onChange={this.handleChange} value={this.state.advertiser_logo_url} /><br />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-3">Name {": "}</label>
                        <div className="col-md-9">
                            <input required type="text" className="form-control" id="name" name="name" onChange={this.handleChange} value={this.state.name} /><br />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-3">Description {": "}</label>
                        <div className="col-md-9">
                            <input required type="text" className="form-control" id="description" name="description" onChange={this.handleChange} value={this.state.description} /><br />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label col-md-3">Lang ID {": "}</label>
                        <div className="col-md-9">
                            <select required className="form-control" onChange={this.handleLangs}>
                                <option>Select</option>
                                {this.state.langs.map((lang) => {
                                    return (
                                        <option id={lang.id} value={lang.lang}>{lang.lang}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-md-offset-2 col-md-10">
                            <input type="button" className="btn btn-default" style={{ float: "right" }} value="Submit" onClick={this.handleSubmit}></input>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default AttachForm;