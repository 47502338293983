//import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ReactSession } from 'react-client-session';
import { APP_CONSTANTS } from 'utils/constants';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../Loader';

var axios = require('axios');
var FormData = require('form-data');
function AddInventory(props) {
    const [key, setKey] = useState("");
    const [position, setPosition] = useState();
    const [type, setType] = useState();
    const [id, setId] = useState("");
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const location = useLocation();
    //console.log("params", props.match.params);

    useEffect(() => {
        console.log(location.state);
        setKey(location.state.key);
        setPosition(location.state.pos);
        setType(location.state.type);
        setId(location.state.id);
        setLoading(false);
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let formdata = new FormData();
        formdata.append("key", key);
        formdata.append("position", position);
        console.log(formdata);
        let access_token = 'Bearer ' + ReactSession.get("access_token");
        console.log(global.config.apiGateway.URL + APP_CONSTANTS.ad_sales)
        console.log(access_token);
        if (type == "add") {
            axios({
                method: "post",
                url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/add_inventory",
                data: formdata,
                headers: {
                    Authorization: access_token,
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                console.log(response);
                setLoading(false);
                history.push("/inventory")
            }).catch((err) => {
                console.log(err);
                setLoading(false)
            })
        }
        else {
            axios({
                method: "post",
                url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/edit_inventory/" + id,
                data: formdata,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: access_token
                }
            }).then((response) => {
                setLoading(false);
                history.push("/inventory");
                console.log(response);
            }).catch((err) => {
                setLoading(false);
                console.log(err);
            })
        }

    }

    return (
        <div className="container">
            {loading ?<Loader /> :
                <form className="form-horizontal col-md-8" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label className="control-label col-md-3">Key: </label>
                        <div className="col-md-9">
                            <select required className="form-control" value={key} onChange={(e) => setKey(e.target.value)}>
                                <option value="" disabled selected hidden>Select</option>
                                <option value="CropDetails">Crop Details</option>
                                <option value="Qna">QNA</option>
                                <option value="NetworkFeed">Network Feed</option>
                            </select>
                        </div>
                    </div><div className="form-group">
                        <label className="control-label col-md-3">Position: </label>
                        <div className="col-md-9">
                            <input required type="number" className="form-control" id="position" name="position" onChange={(e) => setPosition(e.target.value)} value={position} /><br />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-md-offset-2 col-md-10">
                            {type == "add" ? <input type="submit" className="btn btn-default" style={{ float: "right" }} value="Submit"></input> :
                                <input type="submit" className="btn btn-default" style={{ float: "right" }} value="Update"></input>}
                        </div>
                    </div>

                </form>}
        </div>
    )
}

export default AddInventory
