import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ReactSession } from "react-client-session";

import "./App.css";
import {
  AddClientAd,
  AddInventory,
  AttachForm,
  ClientAd,
  Creative,
  ExportLead,
  Inventory,
  LandingPage,
  LandingPageEdit,
  LandingPageForms,
  AddCreative, 
  Navbar,Login,
  Logout,
  PageFormFieldDetails,
  FieldOptions,
  ShowClients,
  UpdateClientAd,
  ViewForm,
} from "components";

class App extends Component {
  constructor() {
    super();
    ReactSession.setStoreType("localStorage");
  }

  componentDidMount() { }
  render() {
    return (
      <BrowserRouter>
        <div className="App container">
          <Navbar />
          <div
            className="content"
            style={{ paddingTop: "70px", paddingBottom: "50px" }}
          >
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/forms" component={LandingPageForms} />
              <Route
                exact
                path="/edit_form/:form_id"
                component={PageFormFieldDetails}
              />
              <Route
                exact
                path="/edit_page_component/:page_id"
                component={LandingPageEdit}
              />
              <Route
                exact
                path="/edit_form/show_options/:field_id"
                component={FieldOptions}
              />
              <Route
                exact
                path="/attach_form/:form_id"
                component={AttachForm}
              />
              <Route
                exact
                path="/list_landing_pages"
                component={LandingPage}
              />
              <Route
                exact
                path="/list_clients"
                component={ShowClients}
              />
              <Route
                exact
                path="/view_form/:form_id"
                component={ViewForm}
              />
              <Route
                exact
                path="/inventory"
                component={Inventory}
              />
              <Route
                exact
                path="/add_inventory"
                component={AddInventory}
              />
              <Route
                exact
                path="/creative"
                component={Creative}
              />
              <Route
                exact
                path="/add_creative"
                component={AddCreative}
              />
              <Route
                exact
                path="/clients_ads"
                component={ClientAd}
              />
              <Route
                exact
                path="/add_clients_ads"
                component={AddClientAd}
              />

              <Route
                exact
                path="/update_clients_ads/:id"
                component={UpdateClientAd}
              />

              <Route
                exact
                path="/export_lead/:id"
                component={ExportLead}
              />

            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
