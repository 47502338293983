import React, { Component, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import { APP_CONSTANTS } from '../../utils/constants';
import ReactPaginate from "react-paginate";
import Loader from '../Loader';
import { formatDateTimeAM } from 'utils/datetime';

function ClientFormDetails(props) {
    const [name, setName] = useState("");
    const handleSubmit = (e) => {
        e.preventDefault();
        var access_token = "Bearer " + ReactSession.get("access_token");
        var formdata = new FormData();
        formdata.append("name", name);
        axios({
            method: "post",
            url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/create_client",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: access_token
            }
        }).then((response) => {
            console.log(response.data);
            window.location.reload();
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <div>
            <div className="col-md-12" style={{ "margin-bottom": "30px" }}>
                <form id="create_field" className="form-horizontal col-md-8" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label className="control-label col-md-3" htmlFor="type">Client Name:</label>
                        <div className="col-md-9">
                            <input required type="text" className="form-control" id="question_text" placeholder="Client Name" onChange={(e) => setName(e.target.value)} value={name} />
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="col-md-offset-2 col-md-10">
                            <button type="submit" className="btn btn-default" style={{ float: "right" }}>Submit</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    )
}


let curPage = 1;
function ShowClients() {
    const [items, setItems] = useState([]);
    const [pageCount, setpageCount] = useState(0);
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(true);
    let limit = 10;

    let access_token = 'Bearer ' + ReactSession.get("access_token");
    useEffect(() => {
        axios({
            method: "get",
            url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + "/clients?page=1&ver=0&lang=hi",
            headers: {
                Authorization: access_token,
            },
        }).then((response) => {
            setpageCount(Math.ceil(response.data.total / limit));
            setItems(response.data.content);
            setLoading(false);
        }).catch((err) => {
            console.log(err)
            setLoading(false);
        })

    }, [])


    const fetchData = async (currentPage) => {
        var items = axios({
            method: "get",
            url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + `/clients?page=${currentPage}`,
            headers: {
                Authorization: access_token,
            },
        }).then((response) => {
            return response.data.content
        }).catch((err) => {
            console.log(err)
            return []
        })
        return items;
    };

    const handlePageClick = async (data) => {
        // console.log(data.selected);
        let currentPage = data.selected + 1;
        curPage = currentPage;
        const dataFormServer = await fetchData(currentPage);
        setItems(dataFormServer);
    };

    const handleFieldChange = (e) => {
        let newClients = [...items];
        let key = e.target.id;
        let value = e.target.value;
        console.log(key + "" + value);
        for (let i = 0; i < newClients.length; i++) {
            let element = newClients[i];
            if (element.client_key === key) {
                element["client_name"] = value;
            }
        }

        setItems(newClients);
    }

    const handleClientUpdate = (e) => {
        let access_token = 'Bearer ' + ReactSession.get("access_token");
        let key = e.target.id;
        let obj = items.find((client) => {
            return client.client_key === key;
        })

        let formData = new FormData();
        formData.append("client_name", obj.client_name);
        formData.append("client_key", obj.client_key);
        axios({
            method: "post",
            url: global.config.apiGateway.URL + APP_CONSTANTS.ad_sales + `/update_client/${obj.id}`,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: access_token
            },
        }).then((response) => {
            console.log(response);
            window.location.reload();
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <div className="container">
            {loading ?<Loader />:
                <>
                    <div class="row">
                        <h2 style={{ display: "inline-block" }} className="my-8">
                            <b>Form Detail:</b>
                            { }
                        </h2>
                        <button
                            className="btn btn-success pull-right mt-5"
                            onClick={() => setShowForm(!showForm)}
                            type="submit"
                        >
                            Add Client
                        </button>
                    </div>
                    {showForm && (
                        <ClientFormDetails
                        />
                    )}
                    <div class="row">
                        <table className="table mb-0">
                            <thead className="bg-light">
                                <tr>
                                    <th scope="col" className="border-0">
                                        Client Id
                                    </th>
                                    <th scope="col" className="border-0">
                                        Client Name
                                    </th>
                                    <th scope="col" className="border-0">
                                        Client Key
                                    </th>
                                    <th scope="col" className="border-0">
                                        Created At
                                    </th>
                                    <th scope="col" className="border-0">
                                        Updated At
                                    </th>
                                    {/* <th scope="col" className="border-0">
                                Actions
                            </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((client) => {
                                    return (
                                        <tr>
                                            <td>{client.id}</td>
                                            <td>
                                                <form className="form-horizontal col-md-8">
                                                    <div className="form-group">


                                                        <div class="input-group input-group-sm">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={client.client_name}
                                                                id={client.client_key}
                                                                onChange={handleFieldChange}>
                                                            </input>

                                                            <div class="input-group-append">
                                                                <button
                                                                    type="button"
                                                                    class="control-label float-right btn btn-sm btn-primary mx-2"
                                                                    id={client.client_key}
                                                                    onClick={handleClientUpdate}>
                                                                    Update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </form>
                                            </td>
                                            <td>{client.client_key}</td>
                                            <td>{formatDateTimeAM(client.created_at)}</td>
                                            <td>{formatDateTimeAM(client.updated_at)}</td>
                                            <td>
                                                <ul>

                                                </ul>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>

                    <div>
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />
                    </div>
                </>}
        </div>
    )
}

export default ShowClients
